import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImgService1 from '../../assets/images/icon/service-one/service-1.png'
import ImgService2 from '../../assets/images/icon/service-one/service-2.png'
import ImgService3 from '../../assets/images/icon/service-one/service-3.png'
import ImgService4 from '../../assets/images/icon/service-one/service-4.png'
import ImgService5 from '../../assets/images/icon/service-one/service-5.png'

import ImgServiceItem1 from '../../assets/images/service/service-item-1.jpg'
import ImgServiceItem2 from '../../assets/images/service/service-item-2.jpg'
import ImgServiceItem3 from '../../assets/images/service/service-item-3.jpg'
import ImgServiceItem4 from '../../assets/images/service/service-item-4.jpg'
import ImgServiceItem5 from '../../assets/images/service/service-item-5.jpg'

const AllServices = () => {
    const navigate = useNavigate();

    const onServiceDetailAI = (e) => {
        e.preventDefault();
        navigate('/service-detail-ai');
        window.location.reload();
    }

    const onServiceDetailFullStackSquad = (e) => {
        e.preventDefault();
        navigate('/service-detail-FullStackSquad');
        window.location.reload();
    }

    const onServiceDetailProductDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-productDevelopment');
        window.location.reload();
    }

    const onServiceDetailApplicationDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-applicationDevlopment');
        window.location.reload();
    }

    const onServiceDetailMetaVerse = (e) => {
        e.preventDefault();
        navigate('/service-detail-MetaVerse');
        window.location.reload();
    }





    return (
        <div className="service-area style-1">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem1})` }}>
                            <div className="more-btn-wrapper">
                                <a href="javascript:void(0)" onClick={onServiceDetailAI} className="more-btn">
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="info-card-inner">
                                <div className="content-wrapper">
                                    <div className="title-wrapper">
                                        <div className="icon">
                                            <img src={ImgService1} alt="icon" />
                                        </div>
                                        <h2 className="title"><a href="javascript:void(0)" onClick={onServiceDetailAI}>AI Development</a></h2>
                                    </div>
                                    <div className="content">
                                        <p className="desc">AI drives innovation, solving complex problems and unlocking opportunities across sectors. We leverage skilled experts and cutting-edge tech to shape the future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem2})` }}>
                            <div className="more-btn-wrapper">
                                <a href="javascript:void(0)" onClick={onServiceDetailFullStackSquad} className="more-btn" >
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="info-card-inner">
                                <div className="content-wrapper">
                                    <div className="title-wrapper">
                                        <div className="icon">
                                            <img src={ImgService2} alt="icon" />
                                        </div>
                                        <h2 className="title"><a href="javascript:void(0)" onClick={onServiceDetailFullStackSquad}>Full Stack Squad</a></h2>
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            A full-stack squad, skilled in front-end and back-end, navigates the entire software development process efficiently for seamless integration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem3})` }}>
                            <div className="more-btn-wrapper">
                                <a href="javascript:void(0)" onClick={onServiceDetailProductDevlopment} className="more-btn">
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="info-card-inner">
                                <div className="content-wrapper">
                                    <div className="title-wrapper">
                                        <div className="icon">
                                            <img src={ImgService3} alt="icon" />
                                        </div>
                                        <h2 className="title"><a href="javascript:void(0)" onClick={onServiceDetailProductDevlopment}>Product Development</a></h2>
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Our product concept & design phase, a cornerstone, involves close collaboration to craft detailed solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem4})` }}>
                            <div className="more-btn-wrapper">
                                <a href="javascript:void(0)" onClick={onServiceDetailApplicationDevlopment} className="more-btn">
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="info-card-inner">
                                <div className="content-wrapper">
                                    <div className="title-wrapper">
                                        <div className="icon">
                                            <img src={ImgService4} alt="icon" />
                                        </div>
                                        <h2 className="title"><a href="javascript:void(0)" onClick={onServiceDetailApplicationDevlopment}>Application Development</a></h2>
                                    </div>
                                    <div className="content">
                                        <p className="desc">Our bespoke application development services are meticulously crafted to align with each client's distinct business goals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="info-card style-two" style={{ backgroundImage: `url(${ImgServiceItem5})` }}>
                            <div className="more-btn-wrapper">
                                <a href="javascript:void(0)" className="more-btn" onClick={onServiceDetailMetaVerse}>
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="info-card-inner">
                                <div className="content-wrapper">
                                    <div className="title-wrapper">
                                        <div className="icon">
                                            <img src={ImgService5} alt="icon" />
                                        </div>
                                        <h2 className="title"><a href="javascript:void(0)" onClick={onServiceDetailMetaVerse}>Metaverse</a></h2>
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Explore digital evolution with our IT services, using the metaverse for business transformation and improved customer interactions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default AllServices;
