// PrivacyPolicy.js

import React, { useEffect, useState } from 'react';
import FooterBar from '../../components/Navbars/footerBar';
import TopNavBar from '../../components/Navbars/topNavBar';
import LoderGif from '../../assets/images/icon/preeloder.gif'

const PrivacyPolicy = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Privacy Policy</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>


                <strong>Privacy Policy</strong>
                <p>
                    At TkTechnico Solutions Pvt. Ltd., we prioritize the confidentiality of your information on the internet. It is crucial for us to outline how we handle the data we receive from you online.
                </p>
                <p>
                    In general, you can explore our presence on the World Wide Web at TkTechnico Solution Pvt. Ltd. without disclosing your identity or sharing personal information. Our web servers collect domain names, not email addresses, to aggregate data such as the number of visits, average time spent on the site, and pages viewed. This information helps us gauge website usage and enhance its content. By accessing our site, you express your unconditional consent for the collection of your domain names.
                </p>
                <p>
                    However, there are instances where we may require specific information from you, such as your name and address. In such cases, we strive to inform you at the time of collection about how we intend to use this personal information. Typically, the collected data is used solely by us to respond to your inquiries, process orders, or provide access to specific account information.
                </p>
                <p>
                    On occasion, we may share email addresses with reputable organizations offering products or services that we believe may interest you. You will have the opportunity to limit access to your information in these cases. Failure to respond to limit the access will be deemed as your consent to the mentioned distribution of your information.
                </p>
                <p>
                    If you register online with one of TkTechnico Solution's business units, the information may be used to offer customized details about TkTechnico Solution's offerings to support your business needs. Cookies, tiny elements of data sent to your browser, may be employed to provide tailored information. By registering with any TkTechnico Solution Pvt. Ltd. business unit online, you express your unconditional consent to receive such cookies.
                </p>
                <p>
                    Periodically, we conduct online surveys to better understand the needs and profile of our visitors. During such surveys, we attempt to inform you about how we plan to use the information at the time of collection.</p>
                <p>
                You acknowledge that providing us with your personal information is voluntary, and any information shared is done so with your full consent and desire to provide such details. We are not obligated to verify the source of the information unless you demonstrate within fifteen days of providing it that the information was given without your free consent to our satisfaction.
                </p>
                <p>
                While TkTechnico Solution's website may contain links to other sites, including partners and affiliates, we are not responsible for the content or privacy practices of these external sites. We strive to link only to sites that share our high standards and respect for privacy."
                </p>
                <p><strong>Contact Us</strong></p>
                <p>
                    If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at info@tktechnico.com.
                </p>
            </div>
            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
