import React from 'react';

// Images
import HeaderLogo from '../../assets/images/logo/logo.png'
import HeaderLogoSticky from '../../assets/images/logo/sticky-logo.png'
import ImgPhoneSupport from '../../assets/images/icon/phone-support.png'
import { useLocation, useNavigate } from 'react-router-dom';




// 
import sidebarLogo from '../../assets/images/logo/black-logo.png'

// 
const TopNavBar = () => {

    const navigate = useNavigate();

    const onHomePage = (e) => {
        e.preventDefault();
        navigate('/');
        window.location.reload();
    }
    const onAboutUs = (e) => {
        e.preventDefault();
        navigate('/about');
        window.location.reload();
    }
    const onServicePage = (e) => {
        e.preventDefault();
        navigate('/service');
        window.location.reload();
    }
    const onTeamPage = (e) => {
        e.preventDefault();
        navigate('/team');
        window.location.reload();
    }
    const onContactUs = (e) => {
        e.preventDefault();
        navigate('/contact');
        window.location.reload();
    }
    const onBlog = (e) => {
        e.preventDefault();
        navigate('/blog');
        window.location.reload();
    }

    const { pathname } = useLocation();

    const isActive = (path) => {
        return pathname === path ? 'active' : '';
    };

    return (
        <div>
            <header class="header-area style-1">
                <div class="header-menu-area sticky-header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="cal-12">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div className="logo">
                                        <a className="standard-logo">
                                            <img onClick={onHomePage} src={HeaderLogo} alt="logo" />
                                            <span onClick={onHomePage} className="bevan-regular">TKTECHNICO</span>
                                        </a>
                                        <a className="sticky-logo">
                                            <img onClick={onHomePage} src={HeaderLogoSticky} alt="logo" />
                                            <span onClick={onHomePage} className="bevan-regular">TKTECHNICO</span>
                                        </a>
                                        <a className="retina-logo">
                                            <img onClick={onHomePage} src={HeaderLogo} alt="logo" />
                                            <span onClick={onHomePage} className='bevan-regular'>TKTECHNICO</span>
                                        </a>
                                    </div>
                                    <div class="menu d-inline-block">
                                        <nav id="main-menu" class="main-menu">
                                            <ul>
                                                <li className={isActive('/')}>
                                                    <a href='#' onClick={onHomePage}>Home</a>
                                                </li>
                                                <li className={isActive('/service')}>
                                                    <a href='#' onClick={onServicePage}>Service</a>
                                                </li>
                                                <li className={isActive('/blog')}>
                                                    <a href='#' onClick={onBlog}>Blog</a>
                                                </li>
                                                <li className={isActive('/about')}>
                                                    <a href='#' onClick={onAboutUs}>About</a>
                                                </li>
                                                <li className={isActive('/contact')}>
                                                    <a href='#' onClick={onContactUs}>Contact</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="search-btn-with-cta-btn">
                                        <div class="search-btn-with-btn">
                                            <div class="btn-wrapper">
                                                <a href="#" onClick={onContactUs} class="theme-btn">Get A Quote</a>
                                            </div>
                                        </div>
                                        <a href="tel:+123-456-7890" class="ct-header-btn">
                                            <div class="icon-wrapper">
                                                <div class="icon">
                                                    <img src={ImgPhoneSupport} alt="phone" />
                                                </div>
                                            </div>
                                            <div class="content-wrapper">
                                                <span class="title">Call Us</span>
                                                <span class="text">+919960803355</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="mobile-menu-bar d-lg-none text-end">
                                        {/* <a href="#" class="mobile-menu-toggle-btn"><i class="fa fa-bars"></i></a> */}
                                        <a href="javascript:void(0)" class="mobile-menu-toggle-btn"><i class="fa fa-bars"></i></a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </header>

            <div className="menu-sidebar-area">
                <div className="menu-sidebar-wrapper">
                    <div className="menu-sidebar-close">
                        <button className="menu-sidebar-close-btn" id="menu_sidebar_close_btn">
                            <i className="fa-solid fa-times"></i>
                        </button>
                    </div>
                    <div className="menu-sidebar-content">
                        <div className="menu-sidebar-logo">
                            <a href="javascript:void(0)" >
                                <img src={sidebarLogo} alt="logo" />
                                <span className="bevan-regular">TKTECHNICO</span>
                            </a>
                        </div>
                        <div className="">
                            <ul className='mob-menu'>
                                <div className={`mob-menu-item ${isActive('/')}`}>
                                    <a href='#' onClick={onHomePage}>Home</a>
                                </div>
                                <div className={` mob-menu-item {isActive('/service')}`}>
                                    <a href='#' onClick={onServicePage}>Service</a>
                                </div>
                                <div className={` mob-menu-item {isActive('/blog')}`}>
                                    <a href='#' onClick={onBlog}>Blog</a>
                                </div>
                                <div className={` mob-menu-item {isActive('/about')}`}>
                                    <a href='#' onClick={onAboutUs}>About</a>
                                </div>
                                <div className={` mob-menu-item {isActive('/contact')}`}>
                                    <a href='#' onClick={onContactUs}>Contact</a>
                                </div>
                            </ul>
                        </div>
                        <div className="menu-sidebar-content">
                            <div className="menu-sidebar-single-widget">
                                <h5 className="menu-sidebar-title">Contact Info</h5>
                                <div className="header-contact-info">
                                    <span><i className="fa-solid fa-location-dot"></i>A-504, Kool Homes Arena, Patil Nagar, Balewadi, Pune, Maharashtra, 411045, IN</span>
                                    <span><a href="mailto:info@tktechnico.com"><i
                                        className="fa-regular fa-envelope"></i>info@tktechnico.com</a> </span>
                                    <span><a href="tel:+919960803355"><i className="fa-solid fa-phone"></i>+919960803355</a></span>
                                </div>
                                <div className="social-profile">
                                    <a href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a>
                                    <a href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                    <a href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                    <a href="javascript:void(0)"><i className="fa-brands fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-overlay"></div>

        </div>
    );
};

export default TopNavBar;
