import React, { useEffect, useState } from 'react';

// 
import companyLogo from '../../assets/images/icon/company-logo.png'
import aboutUsCardImg from '../../assets/images/about/about-card-img-2.jpg'
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
//

//
import sectionBGImg from '../../assets/images/section-bg/cta-bg.jpg'
import FeaturedProjects from '../../components/featuredProjects';
//

// 
import LoderGif from '../../assets/images/icon/preeloder.gif'
import LetsTalk from '../../components/letsTalk';
// 
import ceo from '../../assets/images/team-member/team-1.jpg'
import ceoanddirectoroperation from '../../assets/images/team-member/team-1.jpg'
import director from '../../assets/images/team-member/team-1.jpg'



const AboutUs = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>

            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />

            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">About Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-us-area style-2">
                <div className="container">
                    <div className="section-title">
                        <div className="row">
                            <div className="col-lg-6 align-self-lg-center">
                                <div className="sec-content">
                                    <span className="short-title">About Us</span>
                                    <h2 className="title">Infusing Technology <br />with Passion</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-desc">
                                    <p>
                                        TkTechnico Solutions, a prominent Indian IT services outsourcing company, is committed to catalyzing revenue growth and optimizing business operations through the development and introduction of innovative technologies on the global stage. With a focus on fostering agility and actively contributing to the evolution of businesses, the company strives to provide a comprehensive range of offerings.
                                    </p>
                                    <p>
                                        TkTechnico Solutions propels revenue growth with cutting-edge technologies exceeding industry standards. Specializing in IT services outsourcing, the company enhances business functionality by addressing unique organizational needs and challenges in the fast-paced, ever-evolving business landscape.                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-image-wrapper">
                                <div className="about-image-card">
                                    <div className="main-img-wrapper">
                                        <div className="main-img-inner">
                                            <img className="" src={aboutUsCardImg} alt="about card img" />
                                            <div className="company-label">
                                                <div className="circle-rotate-wrapper">
                                                    <div className="circle-rotate-inner">
                                                        <div className="icon">
                                                            <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                                Award Winning    Digital Agency
                                                            </div>
                                                            <img src={companyLogo} alt="icon" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-info-card style-2">
                                <div className="about-info-content">
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <div className="single-content">
                                                <h3 className="title">Design & Development</h3>
                                                <p>Engaging in thorough project research and design for our clients involves a meticulous examination of every tool, feature, and workflow. Our goal is to identify optimal solutions through careful analysis, ensuring a comprehensive approach to design and development.</p>
                                            </div>
                                            <div className="single-content">
                                                <h3 className="title">Research and Analysis</h3>
                                                <p>Our commitment to research and analysis goes beyond surface-level investigation. We delve deeply into the intricacies of each project, leveraging insights to formulate strategies that not only address immediate needs but also anticipate future challenges.</p>
                                            </div>
                                        </div>
                                        <div className="review-wrapper">
                                            <div className="single-review">
                                                <div className="review">
                                                    <p>Leaders get out in front and stay there by raising
                                                        the standards by which they judge themselves
                                                        – and by which they are willing to be judged.</p>
                                                    <div className="reviewer-info">
                                                        <span className="divider"></span>
                                                        <p>Frederick W. Smith<span><br />Founder and CEO of FedEx</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="cta-area style-3" style={{ backgroundImage: `url(${sectionBGImg})` }}>
                <div className="image-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="call-to-action-card">
                                <div className="content">
                                    <span className="short-title">Visual Insights</span>
                                    <h2 className="title">An open and cooperative methodology <br />to provide excellent<br /> IT Solutions</h2>
                                </div>
                                <div className="btn-wrapper">
                                    <div className="video-popup-btn">
                                        <a href="https://drive.google.com/file/d/1AaTwCOm1Ras9cNiD2_a3j6uYYg28R9t3/preview" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                        <span className="btn-text">Watch Now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* <div className="cta-area style-3 d-none d-md-block" style={{ backgroundImage: `url(${sectionBGImg})` }}>
                <div className="image-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="call-to-action-card">
                                <div className="content">
                                    <span className="short-title">Visual Insights</span>
                                    <h2 className="title">An open and cooperative methodology <br />to provide excellent<br /> IT Solutions</h2>
                                </div>
                                <div className="btn-wrapper">
                                    <div className="video-popup-btn">
                                        <a href="https://www.youtube.com/embed/dkxI_EWVP2s?si=GytDheKEakGlct1L&autoplay=1" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                        <span className="btn-text">Watch Now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="cta-area style-3 d-block d-sm-none" style={{ backgroundImage: `url(${sectionBGImg})` }}>
                <div className="image-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="call-to-action-card">
                                <div className="content">
                                    <span className="short-title">Visual Insights</span>
                                    <h2 className="title">An open and cooperative methodology <br />to provide excellent<br /> IT Solutions</h2>
                                </div>
                                <div className="btn-wrapper">
                                    <div className="video-popup-btn">
                                        <a href="https://www.youtube.com/embed/dkxI_EWVP2s?si=GytDheKEakGlct1L&autoplay=1&mute=1" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                        <span className="btn-text">Watch Now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}







            {/* <div class="team-area style-1">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title">
                                <div class="sec-content">
                                    <span class="short-title">20+ Years of Experience</span>
                                    <h2 class="title">People Leading The <br /> Industry</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-xl-8">
                        <div class="col-lg-3 col-md-6 col-sm-4 team-member-card-wrapper ">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={ceo} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Tushar Kothari</h2>
                                            <p class="desc">CEO & Director</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-4 team-member-card-wrapper mx-auto">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={ceoanddirectoroperation} atl="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Reshma Kothari</h2>
                                            <p class="desc">CEO & Director Operation</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 team-member-card-wrapper">
                            <div class="team-member-card">
                                <div class="image">
                                    <img src={director} alt="team-member" />
                                </div>
                                <div class="content-wrapper">
                                    <div class="team-content-wrapper">
                                        <div class="content">
                                            <h2 class="title">Sunny Patel</h2>
                                            <p class="desc">Director</p>
                                        </div>
                                        <div class="social-profile-link">
                                            <div class="social-profile-btn">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div class="social">
                                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                                                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <FeaturedProjects />

            <LetsTalk />


            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>

        </div>
    );
};

export default AboutUs;
