import React, { useEffect, useState } from 'react';

import ServiceDetailMain from "../../assets/images/service-detail/service-details-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery.jpg"
import outcome from "../../assets/images/service-detail/outcome.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import { useNavigate } from 'react-router-dom';
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailAI = () => {


    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    const navigate = useNavigate();

    const onServiceDetailAI = (e) => {
        e.preventDefault();
        navigate('/service-detail-ai');
        window.location.reload();
    }

    const onServiceDetailFullStackSquad = (e) => {
        e.preventDefault();
        navigate('/service-detail-FullStackSquad');
        window.location.reload();
    }

    const onServiceDetailProductDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-productDevelopment');
        window.location.reload();
    }

    const onServiceDetailApplicationDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-applicationDevlopment');
        window.location.reload();
    }

    const onServiceDetailMetaVerse = (e) => {
        e.preventDefault();
        navigate('/service-detail-MetaVerse');
        window.location.reload();
    }

    // CONTACT FORM

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [subjectMail, setSubjectMail] = useState("");
    const [message, setMessage] = useState("");


    const [validfullName, setValidfullName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [validSubjectMail, setValidSubjectMail] = useState(true);
    const [validMessage, setValidMessage] = useState(true);

    const [emailSent, setEmailSent] = useState(false);


    const onFullName = (e) => {
        setFullName(e.target.value);
        if (e.target.value === "") {
            setValidfullName(false);
        } else {
            setValidfullName(true);
        }
    }

    const onEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(validateEmail(e.target.value))
    }

    const onChangePhoneNumber = (e) => {
        let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
        setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
        if (e.target.value === '') {
            setValidPhoneNumber(false);
        } else {
            setValidPhoneNumber(true);
        }
    }

    const onSubjectMail = (e) => {
        setSubjectMail(e.target.value);
        if (e.target.value === '') {
            setValidSubjectMail(false);
        } else {
            setValidSubjectMail(true);
        }
    }

    const onMessage = (e) => {
        setMessage(e.target.value);
        if (e.target.value === '') {
            setValidMessage(false);
        } else {
            setValidMessage(true);
        }
    }

    const [submitDisabled, setSubmitDisabled] = useState(false);

    async function onContactUsSubmit(e) {
        e.preventDefault();

        setSubmitDisabled(true);

        fullName === '' && setValidfullName(false)
        email === '' && setValidEmail(false)
        phoneNumber === '' && setValidPhoneNumber(false)
        subjectMail === '' && setValidSubjectMail(false)
        message === '' && setValidMessage(false)


        if (fullName && email && phoneNumber && subjectMail && message && validEmail) {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    name: fullName,
                    phoneNumber: phoneNumber,
                    subjectMail: subjectMail,
                    message: message
                })
            };

            await fetch('https://localhost:44338/api/EmailEnquiry', options)
                .then(response => response.json())
                .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
                .catch(err => console.error(err));
        }
        setSubmitDisabled(false);
    }


    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        cleaned = cleaned.slice(0, 10);
        var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
        if (match) {
            return '' + match[1] + ' ' + match[2] + ' ' + match[3];
        }
        return phoneNumberString.toString();
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        }
        return false
    }

    // CONTACT FORM


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">AI Development</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-details-page">
                <div className="container">
                    <div className="row">


                        <div className="col-xl-9 order-1 service-details-wrapper">
                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="service-details" />
                                </div>
                                <div className="text">
                                    <p>Transform your business with our AI Development Service, a dynamic solution harnessing the potential of artificial intelligence. Our skilled developers specialize in crafting tailored AI solutions, from natural language processing to predictive analytics. We prioritize innovation and collaboration, ensuring seamless integration into existing workflows. Whether automating processes or optimizing user interactions, our service delivers tangible results, unlocking new possibilities for your organization in the digital era. Stay ahead with our expertise, transforming data into actionable insights and driving sustainable growth through the power of artificial intelligence.</p>
                                    <p>Our AI Development Service offers businesses advanced solutions tailored to their unique needs. From cutting-edge algorithms to machine learning techniques, we specialize in crafting intelligent systems that enhance efficiency, decision-making, and user experiences. Our team excels in creating custom solutions such as natural language processing and computer vision. Prioritizing innovation and collaboration, we ensure seamless integration into existing workflows, driving tangible results for organizations. Whether automating processes or optimizing user interactions, our service empowers businesses to stay ahead in the digital era, transforming data into actionable insights and unlocking new possibilities for sustainable growth.</p>
                                </div>
                            </div>
                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Key Factors</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Empower with NLP-Driven Generative AI Services</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Unlock the potential of natural language understanding in crafting AI-driven chatbots, language translation systems, and sentiment analysis tools.
                                                        </li>
                                                        <li>
                                                            Elevate customer experiences and enable data-driven decision-making.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Optimize with Predictive Modeling and Forecasting</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Harness Generative AI for predicting trends, understanding customer behavior, and refining business strategies.
                                                        </li>
                                                        <li>
                                                            Drive efficiency and competitiveness through data-driven decision-making.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Revolutionize with Computer Vision Applications</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Redefine visual data interaction using state-of-the-art Generative AI services.
                                                        </li>
                                                        <li>
                                                            Enrich applications with capabilities spanning from image generation to object recognition.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Personalize with Recommendation Systems</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Deliver personalized experiences through advanced Generative AI recommendation systems.
                                                        </li>
                                                        <li>
                                                            Enhance user engagement and satisfaction with personalized content, products, and services.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Innovate Content Generation & Personalization</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Stay ahead in the digital landscape with automated content creation.
                                                        </li>
                                                        <li>
                                                            Utilize Generative AI to craft articles, product descriptions, and personalized marketing messages.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Accelerate Prototyping with Generative Models</h4>
                                            </div>
                                            <div className="content">
                                                <p>

                                                    <ul>
                                                        <li>
                                                            Swiftly iterate and refine designs, optimizing time and resources.
                                                        </li>
                                                        <li>
                                                            Expedite product development using AI-generated models.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Transform Product Development with AI</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Infuse Generative AI into the product development process for rapid innovation.
                                                        </li>
                                                        <li>
                                                            Expedite the product development lifecycle from design to optimization.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Champion Ethical AI Implementation</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    <ul>
                                                        <li>
                                                            Prioritize ethical and responsible AI practices.
                                                        </li>
                                                        <li>
                                                            Develop Generative AI models adhering to industry best practices, ensuring transparency, unbiased outcomes, and compliance with data privacy regulations.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Benefits</h4>
                                        {/* <p>AI boosts efficiency, automates tasks, enhances decision-making, and unlocks insights, driving innovation and growth for businesses.</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    AI automates repetitive tasks, increasing operational efficiency and allowing employees to focus on more strategic and creative aspects.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    AI analyzes vast datasets rapidly, providing valuable insights that facilitate informed decision-making, reducing errors and enhancing accuracy.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    AI enables personalized user experiences through data analysis, tailoring recommendations and interactions, thereby enhancing customer satisfaction and loyalty.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    AI fuels innovation through advanced tech like language processing, vision, and robotics, unlocking possibilities across industries.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Outcome</h4>
                                        {/* <p>The outcomes of AI implementation are diverse and impact various aspects of businesses and society, including:</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    AI automates tasks, reducing manual effort and time, leading to improved operational efficiency.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    AI provides data-driven insights, enhancing decision-making processes with accurate and timely information.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Automation and optimization of workflows through AI contribute to increased productivity in various industries.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    AI fosters the development of innovative technologies, creating new opportunities for businesses and driving economic growth.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title">What is artificial intelligence (AI)?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Artificial Intelligence (AI) refers to the development of computer systems capable of performing tasks that typically require human intelligence. These tasks include learning, reasoning, problem-solving, perception, and language understanding.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title"> How is machine learning different from AI?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Machine learning is a subset of AI that focuses on developing algorithms allowing systems to learn from data and improve their performance over time. AI encompasses a broader range of technologies, including machine learning, natural language processing, and computer vision.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">What are the potential applications of AI in business?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>AI has various applications in business, such as automating routine tasks, improving decision-making through data analysis, enhancing customer experiences with personalized interactions, and optimizing processes for increased efficiency and productivity.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">Are there ethical concerns associated with AI?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Yes, ethical concerns in AI include issues like bias in algorithms, privacy implications, job displacement, and the responsible use of AI in decision-making. Addressing these concerns involves implementing ethical guidelines, transparency in AI systems, and ongoing discussions on responsible AI development and deployment.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-3 order-2 order-xl-1">
                            <div class="service-sidebar">
                                <div class="widget widget_service_categories">
                                    <ul>
                                        <li class="active">
                                            {/* <a href="#"><i class="fa-solid fa-brain"></i>AI Development</a> */}
                                            <a href="#" onClick={onServiceDetailAI}>AI Development</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailFullStackSquad}>Full Stack Squad</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailProductDevlopment}>Product Development</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailApplicationDevlopment}>Application Development</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailMetaVerse}>Metaverse</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_contact_form">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Get A Quote</h4>
                                    </div>

                                    {emailSent ?
                                        <>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                                <div className="thank-you-heading">
                                                    Thank You, {fullName}
                                                </div>
                                                <div className="thank-you-sub-heading">
                                                    Our team will be in touch with you soon.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div class="comment-respond">
                                            <form action="#" method="post" class="comment-form">
                                                <div class="row gx-2">
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="author" type="text" placeholder="Your name*"
                                                                className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" value={fullName} onChange={e => onFullName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-email">
                                                            <input name="email" type="text" placeholder="Your email*"
                                                                className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" value={email} onChange={e => onEmail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="phone" type="text" placeholder="Your phone"
                                                                className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" value={phoneNumber} onChange={e => onChangePhoneNumber(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="website" type="text" placeholder="Subject"
                                                                className={`form-control ${!validSubjectMail && 'is-invalid'}`} id="exampleFormControlInput1" value={subjectMail} onChange={e => onSubjectMail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-message">
                                                            <textarea name="comment" cols="20" rows="3" placeholder="Start writing your message"
                                                                className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" value={message} onChange={e => onMessage(e)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        {

                                                            !submitDisabled ?
                                                                <button className="theme-btn" type="submit" onClick={onContactUsSubmit} disabled={submitDisabled}>Submit Now</button>
                                                                :

                                                                <div className="loader">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>

                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                </div>
                                <div class="widget widget_service_promotion">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Need Help?</h4>
                                    </div>
                                    <div class="promotion-card">
                                        <p class="short-desc">Your digital success, our priority. Innovative IT solutions for seamless business evolution.</p>
                                        <a href="tel:+919960803355" class="secondary-btn">
                                            <span class="icon">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <div class="btn-text">
                                                <p>Call Us</p>
                                                <h4>+919960803355</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>



            <FooterBar />
                            <div id="scrollTop" class="scrollup-wrapper">
                    <div class="scrollup-btn">
                        <i class="fa fa-angle-up"></i>
                    </div>
                </div>
            

        </div>
    );
};

export default ServiceDetailAI;
