import React from 'react'
import { useEffect, useState } from 'react'
import TopNavBar from '../../components/Navbars/topNavBar'
import FooterBar from '../../components/Navbars/footerBar'
import LoderGif from '../../assets/images/icon/preeloder.gif'

import projectimg1 from '../../assets/images/project-detail/project-details-1.jpg'
import projectimg2 from '../../assets/images/project-detail/project-details-2.jpg'
import projectimg3 from '../../assets/images/project-detail/project-details-3.jpg'

import portfolio1 from '../../assets/images/project/p1.jpg'
import portfolio3 from '../../assets/images/project/p3.jpg'
import portfolio5 from '../../assets/images/project/p5.jpg'
import { useNavigate } from 'react-router-dom'


const ProjectDetail = () => {

    const navigate = useNavigate();

    // const onProjectDetailAI = (e) => {
    //     e.preventDefault();
    //     navigate('/projectdetail');
    //     window.location.reload();
    // }

    const onProjectDetailWebDev = (e) => {
        e.preventDefault();
        navigate('/projectdetail-webDevelopment');
        window.location.reload();
    }

    const onProjectDetailAppDev = (e) => {
        e.preventDefault();
        navigate('/projectdetail-applicationDevelopment');
        window.location.reload();
    }

    const onProjectDetailSoftwareTesting = (e) => {
        e.preventDefault();
        navigate('/projectdetail-softwareTesting');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }
            <TopNavBar />
            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Product Delivery for AI Devlopment</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="project-detail-area">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-12 col-lg-8">
                            <div className="project-gallery-image">
                                <img src={projectimg1} alt="project-details" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="d-flex flex-column gap-4">
                                <div className="project-gallery-image">
                                    <img src={projectimg2} alt="project-details" />
                                </div>
                                <div className="project-gallery-image">
                                    <img src={projectimg3} alt="project-details" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-65">
                        <div className="col-12 col-xl-4">
                            <div className="project-info-widget-wrapper">
                                <div className="project-info-widget">
                                    <div className="info-item">
                                        <h3>Project Type</h3>
                                        <p>AI Development</p>
                                    </div>
                                    <div className="info-item">
                                        <h3>Duration</h3>
                                        <p>1 Year</p>
                                    </div>
                                    <div className="info-item">
                                        <h3>Task</h3>
                                        <p>Research, Development</p>
                                    </div>
                                </div>
                                <div className="social-share">
                                    <span className="social-share-title">Share</span>
                                    <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                    <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                    <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-8 project-details-padding-left">
                            <div className="tiny-content">
                                <p>Our product delivery for AI development is designed to empower businesses with cutting-edge artificial intelligence solutions. From inception to implementation, we provide a comprehensive service that includes data analysis, model development, and integration into existing systems. Our expert team of AI developers ensures a seamless delivery process, leveraging state-of-the-art algorithms and technologies. We prioritize user-friendly interfaces and scalability, allowing our clients to harness the full potential of AI in their applications. With a focus on customization and continuous improvement, our product delivery for AI development caters to diverse industry needs, driving innovation and efficiency.</p>
                            </div>
                            <div className="mt-30 mt-lg-0">
                                <h2 className="project-details-title">Problems</h2>
                                <p>Navigating the landscape of AI development and product delivery presents a myriad of challenges. Securing high-quality, unbiased data for training, and grappling with a shortage of skilled professionals are foundational hurdles. The opaque nature of some AI models demands a concerted effort towards interpretability and explainability. Ensuring scalability and smooth integration into existing systems, while addressing ethical concerns related to biases and fairness, adds complexity. Security and privacy considerations loom large, as AI systems handle sensitive data. Staying abreast of ever-evolving regulatory landscapes and balancing development costs against anticipated returns are ongoing challenges. Continuous learning mechanisms for AI models and fostering user trust are imperative for sustained success. This intricate web of challenges necessitates a multidisciplinary approach, incorporating expertise in AI, data science, ethics, and domain-specific knowledge to navigate the journey from development to successful product delivery.</p>
                            </div>
                            <div className="row mt-60 gx-sm-5">
                                <div className="col-12">
                                    <h2 className="project-details-title">Solutions</h2>
                                </div>
                                <div className="col-12 col-xl-6 col-md-6">
                                    <div className="list-item-wrapper">
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Implement rigorous data quality checks and cleansing procedures to ensure accurate and unbiased datasets.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Invest in training programs to bridge the skill gap, fostering a team with expertise in the latest AI technologies.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Utilize interpretable AI models and tools to enhance transparency and facilitate understanding of model decisions.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Design scalable AI architectures that can efficiently handle increasing data volumes and user demands.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Develop and adhere to ethical AI frameworks to identify and mitigate biases, promoting fairness and accountability.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 col-md-6">
                                    <div className="list-item-wrapper">
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Implement state-of-the-art security protocols to safeguard AI systems and protect sensitive data.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Establish a dedicated team to monitor and ensure compliance with evolving AI regulations and standards.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Conduct thorough cost-benefit analyses to optimize resource allocation and justify AI development investments.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Implement mechanisms for continuous learning and adaptation, allowing AI models to evolve and improve over time.</p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">Educate users about AI functionalities, building trust through transparent communication about how their data is used and processed.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="process-step-area style-2">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="project-details-title">Process</h2>
                                        <p>AI development process into three key stages: Research, Development, and Deployment.</p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>01</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Research</h2>
                                                <p className="desc">Identify the problem and assess AI feasibility.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>02</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Development</h2>
                                                <p className="desc">Design AI model architecture.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>03</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Deploy</h2>
                                                <p className="desc">Check scalability and implement security.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-40">
                                    <h2 className="project-details-title">Results</h2>
                                    <p>AI development endeavor are gauged through diverse metrics. These encompass the model's performance, assessed by accuracy and other relevant metrics, along with validation and testing outcomes to ensure adaptability to new data. The model's interpretability and seamless integration into the intended system are crucial. Scalability, security effectiveness, and adherence to regulations are also pivotal benchmarks. User acceptance, based on feedback from testing, indicates the solution's practical success. Deployment success, ongoing monitoring, and maintenance reports contribute to a holistic evaluation, guiding potential refinements and ensuring sustained efficacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="simillar-project-title mb-50">
                                    <h2>You Also Might Like</h2>
                                </div>
                            </div>
                            <div className="portfolio-slider-wrapper" dir="ltr" id="related_portfolio">
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio1} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Web Development</span>
                                                    <h3 className="title"><a href="#" onClick={onProjectDetailWebDev}>Transformative Potential: Navigating the World of Artificial Intelligence in a Few Words.</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="#" onClick={onProjectDetailWebDev}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio3} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Software Testing</span>
                                                    <h3 className="title"><a href="#" onClick={onProjectDetailSoftwareTesting}>Immersive Experiences: Strategic Software Testing: Ensuring Quality and Reliability in Product Delivery</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="#" onClick={onProjectDetailSoftwareTesting}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio5} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Application Development</span>
                                                    <h3 className="title"><a href="#" onClick={onProjectDetailAppDev}>Innovation Unleashed: The Evolving Landscape of Software Development in the Modern Era.</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="#" onClick={onProjectDetailAppDev}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetail