import React, { useEffect, useState } from 'react';
// Images
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
// 
import LoderGif from '../../assets/images/icon/preeloder.gif'
// 

const ContactUs = () => {



    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [subjectMail, setSubjectMail] = useState("");
    const [message, setMessage] = useState("");


    const [validfullName, setValidfullName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [validSubjectMail, setValidSubjectMail] = useState(true);
    const [validMessage, setValidMessage] = useState(true);

    const [emailSent, setEmailSent] = useState(false);


    const onFullName = (e) => {
        setFullName(e.target.value);
        if (e.target.value === "") {
            setValidfullName(false);
        } else {
            setValidfullName(true);
        }
    }

    const onEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(validateEmail(e.target.value))
    }

    const onChangePhoneNumber = (e) => {
        let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
        setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
        if (e.target.value === '') {
            setValidPhoneNumber(false);
        } else {
            setValidPhoneNumber(true);
        }
    }

    const onSubjectMail = (e) => {
        setSubjectMail(e.target.value);
        if (e.target.value === '') {
            setValidSubjectMail(false);
        } else {
            setValidSubjectMail(true);
        }
    }

    const onMessage = (e) => {
        setMessage(e.target.value);
        if (e.target.value === '') {
            setValidMessage(false);
        } else {
            setValidMessage(true);
        }
    }

    const [submitDisabled, setSubmitDisabled] = useState(false);

    async function onContactUsSubmit(e) {
        e.preventDefault();

        setSubmitDisabled(true);

        fullName === '' && setValidfullName(false)
        email === '' && setValidEmail(false)
        phoneNumber === '' && setValidPhoneNumber(false)
        subjectMail === '' && setValidSubjectMail(false)
        message === '' && setValidMessage(false)


        if (fullName && email && phoneNumber && subjectMail && message && validEmail) {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    name: fullName,
                    phoneNumber: phoneNumber,
                    subjectMail: subjectMail,
                    message: message
                })
            };

            await fetch('https://localhost:44338/api/EmailEnquiry', options)
                .then(response => response.json())
                .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
                .catch(err => console.error(err));
        }
        setSubmitDisabled(false);
    }


    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        cleaned = cleaned.slice(0, 10);
        var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
        if (match) {
            return '' + match[1] + ' ' + match[2] + ' ' + match[3];
        }
        return phoneNumberString.toString();
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        }
        return false
    }


    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Contact</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="contact-form-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="contact-info-area">
                                <div className="icon-card style-four">
                                    <div className="icon">
                                        <i className="fa-solid fa-location-dot"></i>
                                    </div>
                                    <div className="content">
                                        <h2 className="title">Address</h2>
                                        <ul>
                                            <li>
                                                <p className="desc">A-504, Kool Homes Arena, Patil Nagar, Balewadi, Pune, Maharashtra, 411045, IN</p>
                                            </li>
                                            <li>
                                                <p className="desc">102, Rainbow Society, Baner, Pune, Maharashtra, 411045, IN</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="icon-card style-four">
                                    <div className="icon">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="content">
                                        <h2 className="title">Phone</h2>
                                        <a href="tel:+919960803355" className="desc">+919960803355</a>
                                    </div>
                                </div>
                                <div className="icon-card style-four">
                                    <div className="icon">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <div className="content">
                                        <h2 className="title">Email</h2>
                                        <a href="mailto:info@tktechnico.com" className="desc">info@tktechnico.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="comment-respond">
                                <div className="post-comments-title">
                                    <h2>Write Us A Message</h2>
                                </div>

                                {emailSent ?
                                    <>
                                        <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                            <div className="thank-you-heading">
                                                Thank You, {fullName}
                                            </div>
                                            <div className="thank-you-sub-heading">
                                                Our team will be in touch with you soon.
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <form action="#" method="post" className="comment-form">
                                        <div className="row gx-2">
                                            <div className="col-xl-6">
                                                <div className="contacts-name">
                                                    <input name="author" type="text" placeholder="Your name*"
                                                        className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" value={fullName} onChange={e => onFullName(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="contacts-email">
                                                    <input name="email" type="text" placeholder="Your email*"
                                                        className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" value={email} onChange={e => onEmail(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="contacts-name">
                                                    <input name="phone" type="text" placeholder="Your phone"
                                                        className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" value={phoneNumber} onChange={e => onChangePhoneNumber(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="contacts-name">
                                                    <input name="website" type="text" placeholder="Subject"
                                                        className={`form-control ${!validSubjectMail && 'is-invalid'}`} id="exampleFormControlInput1" value={subjectMail} onChange={e => onSubjectMail(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="contacts-message">
                                                    <textarea name="comment" cols="20" rows="3" placeholder="Start writing your message"
                                                        className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" value={message} onChange={e => onMessage(e)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                {

                                                    !submitDisabled ?
                                                        <button className="theme-btn" type="submit" onClick={onContactUsSubmit} disabled={submitDisabled}>Submit Now</button>
                                                        :

                                                        <div className="loader">
                                                            <div className="dot"></div>
                                                            <div className="dot"></div>
                                                            <div className="dot"></div>
                                                        </div>

                                                }
                                            </div>
                                        </div>
                                    </form>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="contact-map-area">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="map-widget">
                                <iframe
                                    title="Google Maps - Location 1"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15128.648292449981!2d73.76248866260639!3d18.56673049707711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9e501aec049%3A0x6b3b032258c1b098!2sTktechnico%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin"
                                    width="48%"
                                    height="450px"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="map-widget">
                                <iframe
                                    title="Google Maps - Location 2"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.1626302932227!2d73.77021347592816!3d18.56670536776193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ea64f6e325fac9%3A0x6e280451d2e275e2!2sTktechnico%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin"
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <FooterBar />

            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>

        </div>
    );
};

export default ContactUs;
