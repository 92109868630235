import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/aboutUs/aboutUs';
import ContactUs from './pages/contactUs/contactUs';
import HomePage from './pages/homePage/homePage';
import ServicePage from './pages/servicePage/servicePage';
import BlogPage from './pages/blogPage/blogPage';
import ProjectDetail from './pages/projectDetails/projectDetail';
import ServiceDetailAI from './pages/servicePage/serviceDetailAI';
import ServiceDetailApplicationDevlopment from './pages/servicePage/serviceDetailApplicationDevlopment';
import ServiceDetailCloudTechnology from './pages/servicePage/serviceDetailCloudTechnology';
import ServiceDetailProfessionalConsultancy from './pages/servicePage/serviceDetailProfessionalConsultancy';
import ServiceDetailTechnologyTraining from './pages/servicePage/serviceDetailTechnologyTraining';
import ApplicationDevelopmentDetail from './pages/projectDetails/ApplicationDevelopmentDetail';
import WebDevelopmentDetail from './pages/projectDetails/WebDevelopmentDetail';
import SoftwareTestingDetail from './pages/projectDetails/SoftwareTestingDetails';
import BlogDetailDigitalLandscape from './pages/blogPage/blogDetailDigitalLandscape';
import BlogDetailCodingJourney from './pages/blogPage/blogDetailCodingJourney';
import BlogDetailStrategiesBuilding from './pages/blogPage/blogDetailStrategiesBuilding';
import BlogDetailStoringFiles from './pages/blogPage/blogDetailStoringFiles';
import BlogDetailCloudComputing from './pages/blogPage/blogDetailCloudComputing';
import TermsAndConditions from './pages/ConditionPrivacy/Terms&Condition';
import PrivacyPolicy from './pages/ConditionPrivacy/PrivacyPolicy';
import ServiceDetailProductDevlopment from './pages/servicePage/serviceDetailProductDevlopment';
import ServiceDetailMetaVerse from './pages/servicePage/serviceDetailMetaVerse';
import ServiceDetailFullStackSquad from './pages/servicePage/serviceDetailFullStackSquad';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />

        <Route path="/service" element={<ServicePage />} />
        <Route path="/service-detail-ai" element={<ServiceDetailAI />} />
        <Route path="/service-detail-applicationDevlopment" element={<ServiceDetailApplicationDevlopment />} />
        <Route path="/service-detail-cloudTechnology" element={<ServiceDetailCloudTechnology />} />
        <Route path="/service-detail-technologytraining" element={<ServiceDetailTechnologyTraining />} />
        <Route path="/service-detail-professionalconsultancy" element={<ServiceDetailProfessionalConsultancy />} />
        <Route path="/service-detail-productDevelopment" element={<ServiceDetailProductDevlopment />} />
        <Route path="/service-detail-MetaVerse" element={<ServiceDetailMetaVerse />} />
        <Route path="/service-detail-FullStackSquad" element={<ServiceDetailFullStackSquad />} />

        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blogdetail-digitalLandscape" element={<BlogDetailDigitalLandscape />} />
        <Route path="/blogdetail-codingJourney" element={<BlogDetailCodingJourney />} />
        <Route path="/blogdetail-cloudComputing" element={<BlogDetailCloudComputing />} />
        <Route path="/blogdetail-strategiesBuilding" element={<BlogDetailStrategiesBuilding />} />
        <Route path="/blogdetail-storingFiles" element={<BlogDetailStoringFiles />} />

        <Route path="/projectdetail" element={<ProjectDetail />} />
        <Route path="/projectdetail-applicationDevelopment" element={<ApplicationDevelopmentDetail />} />
        <Route path="/projectdetail-webDevelopment" element={<WebDevelopmentDetail />} />
        <Route path="/projectdetail-softwareTesting" element={<SoftwareTestingDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
