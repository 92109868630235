import React, { useEffect, useState } from 'react';
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';

import blogimage1 from '../../assets/images/blog/b1.jpg'
import blogimage2 from '../../assets/images/blog/b2.jpg'
import blogimage3 from '../../assets/images/blog/b3.jpg'
import blogimage4 from '../../assets/images/blog/b4.jpg'
import blogimage5 from '../../assets/images/blog/b5.jpg'

import blogimagerp1 from '../../assets/images/blog/rp-1.jpg'
import blogimagerp2 from '../../assets/images/blog/rp-2.jpg'
import blogimagerp3 from '../../assets/images/blog/rp-3.jpg'

import instapost1 from '../../assets/images/instagram/instagram-1.jpg'
import instapost2 from '../../assets/images/instagram/instagram-2.jpg'
import instapost3 from '../../assets/images/instagram/instagram-3.jpg'
import instapost4 from '../../assets/images/instagram/instagram-4.jpg'
import instapost5 from '../../assets/images/instagram/instagram-5.jpg'
import instapost6 from '../../assets/images/instagram/instagram-6.jpg'

// 
import LoderGif from '../../assets/images/icon/preeloder.gif'
import { useNavigate } from 'react-router-dom';
// 

const BlogPage = () => {

    const navigate = useNavigate();

    const onBlogDetailDigitalLandscape = (e) => {
        e.preventDefault();
        navigate('/blogdetail-digitalLandscape');
        window.location.reload();
    }
    const onBlogDetailCodingJourney = (e) => {
        e.preventDefault();
        navigate('/blogdetail-codingJourney');
        window.location.reload();
    }
    const onBlogDetailCloudComputing = (e) => {
        e.preventDefault();
        navigate('/blogdetail-cloudComputing');
        window.location.reload();
    }
    const onBlogDetailStrategiesBuilding = (e) => {
        e.preventDefault();
        navigate('/blogdetail-strategiesBuilding');
        window.location.reload();
    }
    const onBlogDetailStoringFiles = (e) => {
        e.preventDefault();
        navigate('/blogdetail-storingFiles');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (

        <div className="div-area style-1">
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />

            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Our Blog</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 blog-post">
                            <article className="single-post-item format-image">
                                <div className="post-thumbnail">
                                    <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape}>
                                        <img src={blogimage1} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>February 06, 2024</span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape}>Safeguarding Data in the Digital Landscape: A Deep Dive into Privacy and Protection</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>
                                            In an era dominated by digital advancements, the critical importance of data privacy and protection cannot be overstated. The increasing integration of technology into our daily lives has led to the generation and exchange of vast amounts of personal and sensitive information. As we navigate this interconnected digital age, ensuring the security and privacy of our data has become a paramount concern.
                                        </p>
                                    </div>
                                    <div className="read-more">
                                        <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape} className="theme-btn" >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                            <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-video">
                                <div className="post-thumbnail">
                                    <a href="javascript:void(0)" onClick={onBlogDetailCodingJourney}>
                                        <img src={blogimage2} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>January 16, 2024</span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailCodingJourney}>Navigating the Digital Horizon: Insights and Innovations in the IT Business Landscape</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>
                                        Welcome to our IT business blog, where we delve into the dynamic world of technology, exploring the latest trends, strategies, and innovations shaping the future of IT enterprises. Join us on this journey as we unravel the complexities, share expert insights, and provide actionable advice to empower your business in the ever-evolving digital landscape.
                                        </p>
                                    </div>
                                    <div className="read-more">
                                        <a href="javascript:void(0)" className="theme-btn" onClick={onBlogDetailCodingJourney} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                            <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-gallery">
                                <div className="post-thumbnail">
                                    <a href="javascript:void(0)" onClick={onBlogDetailCloudComputing}>
                                        <img src={blogimage3} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i><span>January 01, 2024</span></span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailCloudComputing}>Navigating the Skies: Cloud Computing as the Beacon for the Future of IT</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>Cloud computing has emerged as a transformative force, reshaping the landscape of IT infrastructure. As organizations increasingly shift their operations to the cloud, this blog explores why cloud computing is not just a trend but the definitive future of IT.</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="javascript:void(0)" className="theme-btn" onClick={onBlogDetailCloudComputing} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                            <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-audio">
                                <div className="post-thumbnail">
                                    <a href="javascript:void(0)" onClick={onBlogDetailStrategiesBuilding}>
                                        <img src={blogimage4} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>December 12, 2023</span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailStrategiesBuilding}>E-Commerce Excellence: Unveiling Strategies for Building a Thriving Online Store</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>In the dynamic landscape of digital commerce, establishing and growing a successful online store requires a thoughtful blend of strategy and execution. This blog unravels key strategies that pave the way for building a thriving and sustainable online retail presence.</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="javascript:void(0)" className="theme-btn" onClick={onBlogDetailStrategiesBuilding} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                            <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="single-post-item format-image">
                                <div className="post-thumbnail">
                                    <a href="javascript:void(0)" onClick={onBlogDetailStoringFiles}>
                                        <img src={blogimage5} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>November 29, 2023</span>
                                    </div>
                                    <h3 className="post-title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailStoringFiles}>Beyond Boundaries: Mastering the Art of Storing and Accessing Your Files Anywhere</a>
                                    </h3>
                                    <div className="post-content">
                                        <p>In an era defined by mobility and connectivity, the ability to store and access files from anywhere has become a game-changer. This blog navigates the landscape of file storage solutions, unveiling strategies to seamlessly manage your digital assets across diverse devices and locations.</p>
                                    </div>
                                    <div className="read-more">
                                        <a href="javascript:void(0)" className="theme-btn" onClick={onBlogDetailStoringFiles} >Read More</a>
                                        <div className="social-share">
                                            <span className="social-share-title">share</span>
                                            {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                            <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="sidebar">
                                <div className="widget widget_latest_post">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Recent Posts</h4>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp1} onClick={onBlogDetailDigitalLandscape} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape} >The Digital Landscape</a>

                                                </h3>
                                                <span className="latest-post-meta">February 06, 2024</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp2} onClick={onBlogDetailCodingJourney} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="javascript:void(0)" onClick={onBlogDetailCodingJourney} >Insights and Innovations in the IT</a>
                                                </h3>
                                                <span className="latest-post-meta">January 16, 2024</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp3} onClick={onBlogDetailCloudComputing} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="javascript:void(0)" onClick={onBlogDetailCloudComputing} >Cloud Computing The Future Of IT</a>
                                                </h3>
                                                <span className="latest-post-meta">January 01, 2024</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget_instagram_feed">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Gallery</h4>
                                    </div>
                                    <div className="widget-instagram-feed">
                                        <div className="single-instagram-feed">
                                            <img src={instapost1} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost2} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost3} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost4} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost5} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost6} alt="instagram photo" />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_social_profile">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Follow Us</h4>
                                    </div>
                                    <div className="social-profile">
                                        {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                        <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                        <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        <a className="instagram" href="javascript:void(0)"><i className="fa-brands fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>

    );
};

export default BlogPage