import React from 'react'
import { useEffect, useState } from 'react'
import TopNavBar from '../../components/Navbars/topNavBar'
import FooterBar from '../../components/Navbars/footerBar'
import LoderGif from '../../assets/images/icon/preeloder.gif'

import projectimg1 from '../../assets/images/project-detail/project-details-1-appDev.jpg'
import projectimg2 from '../../assets/images/project-detail/project-details-2-appDev.jpg'
import projectimg3 from '../../assets/images/project-detail/project-details-3-appDev.jpg'

import portfolio1 from '../../assets/images/project/p1.jpg'
import portfolio3 from '../../assets/images/project/p3.jpg'
import portfolio5 from '../../assets/images/project/p5.jpg'
import portfolio7 from '../../assets/images/project/p7.jpg'
import portfolio9 from '../../assets/images/project/p9.jpg'
import { useNavigate } from 'react-router-dom'


const ApplicationDevelopmentDetail = () => {

    const navigate = useNavigate();

    const onProjectDetailAI = (e) => {
        e.preventDefault();
        navigate('/projectdetail');
        window.location.reload();
    }

    // const onProjectDetailAppDev = (e) => {
    //     e.preventDefault();
    //     navigate('/projectdetail-applicationDevelopment');
    //     window.location.reload();
    // }

    const onProjectDetailSoftwareTesting = (e) => {
        e.preventDefault();
        navigate('/projectdetail-softwareTesting');
        window.location.reload();
    }

    const onProjectDetailWebDev = (e) => {
        e.preventDefault();
        navigate('/projectdetail-webDevelopment');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }
            <TopNavBar />
            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Product Delivery for App Development</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="project-detail-area">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-12 col-lg-8">
                            <div className="project-gallery-image">
                                <img src={projectimg1} alt="project-details" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="d-flex flex-column gap-4">
                                <div className="project-gallery-image">
                                    <img src={projectimg2} alt="project-details" />
                                </div>
                                <div className="project-gallery-image">
                                    <img src={projectimg3} alt="project-details" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-65">
                        <div className="col-12 col-xl-4">
                            <div className="project-info-widget-wrapper">
                                <div className="project-info-widget">
                                    <div className="info-item">
                                        <h3>Project Type</h3>
                                        <p>Application Development</p>
                                    </div>
                                    <div className="info-item">
                                        <h3>Duration</h3>
                                        <p>1 Year</p>
                                    </div>
                                    <div className="info-item">
                                        <h3>Task</h3>
                                        <p>Research, Development</p>
                                    </div>
                                </div>
                                <div className="social-share">
                                    <span className="social-share-title">Share</span>
                                    {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                    <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                    <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-8 project-details-padding-left">
                            <div className="tiny-content">
                                <p>
                                    Application development is a dynamic process that involves the creation and enhancement of software applications to address specific needs or tasks. This comprehensive undertaking begins with meticulous planning, where the project's requirements and objectives are clearly defined. The development phase follows, encompassing coding, testing, and debugging to ensure the application's functionality and performance meet the specified criteria. Throughout this process, a keen emphasis is placed on user experience and interface design to enhance usability. Continuous collaboration between developers, designers, and stakeholders ensures alignment with expectations. Once developed, rigorous testing is conducted to identify and rectify any issues. Deployment involves making the application accessible to users, and ongoing maintenance ensures its optimal performance and adaptation to evolving requirements. The overarching goal of application development is to create reliable, user-friendly, and efficient software solutions that cater to the unique needs of businesses or end-users.
                                </p>
                            </div>
                            <div className="mt-30 mt-lg-0">
                                <h2 className="project-details-title">Problems</h2>
                                <p>
                                    Despite the strategic planning and meticulous execution involved in application development, various challenges can arise throughout the process. One common issue is scope creep, where project requirements expand beyond the initially defined parameters, leading to timeline extensions and resource strain. Communication gaps between development teams and stakeholders may result in misunderstandings and deviations from the desired outcome. Technical complexities, such as compatibility issues or integration challenges with existing systems, can impede the smooth development flow. Additionally, evolving technologies and market trends may necessitate adaptations, potentially impacting the original development plan. Balancing the need for innovation with the constraints of time and resources requires continuous monitoring and agile problem-solving. Navigating these challenges demands a proactive approach, effective communication, and a commitment to adaptability throughout the application development lifecycle.
                                </p>
                            </div>
                            <div className="row mt-60 gx-sm-5">
                                <div className="col-12">
                                    <h2 className="project-details-title">Solutions</h2>
                                </div>
                                <div className="col-12 col-xl-6 col-md-6">
                                    <div className="list-item-wrapper">
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Establish a robust process for requirements gathering, involving all stakeholders.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Break down projects into smaller sprints, allowing for iterative development and continuous feedback.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Implement continuous integration and continuous deployment (CI/CD) pipelines to automate key development processes, including testing and deployment.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Integrate security measures throughout the development lifecycle, including code reviews and penetration testing.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Incorporate compliance measures into the development process and seek legal guidance as needed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 col-md-6">
                                    <div className="list-item-wrapper">
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Plan deployments meticulously, including rollback strategies in case of issues.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Implement monitoring tools to track application performance and detect issues early.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Develop a robust plan for ongoing maintenance, including allocating resources for updates and bug fixes.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Anticipate future growth and plan for scalability to accommodate increasing user loads or expanding feature sets.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Prioritize tasks based on criticality and impact on users.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="process-step-area style-2">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="project-details-title">Process</h2>
                                        <p>Application development process into three key stages: Research, Development, and Deployment.</p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>01</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Research</h2>
                                                <p className="desc">
                                                    Draft detailed requirements documentation and formulate an all project plan.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>02</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Development</h2>
                                                <p className="desc">
                                                    Choose agile methods for flexibility. Divide the project into sprints and prioritize features based on user value.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>03</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Deploy</h2>
                                                <p className="desc">
                                                    Set up contingency plans to address unforeseen issues during deployment by implementing rollback.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-40">
                                    <h2 className="project-details-title">Results</h2>
                                    <p>
                                        The outcome of the product delivery in application development encompasses the successful release and deployment of the developed application. This includes ensuring that the application meets the specified requirements, functions as intended, and provides a positive user experience. The result also involves addressing any identified issues or bugs through thorough testing and validation processes. Ultimately, the product delivery phase signifies the culmination of the development efforts, marking the availability of the application for use by end-users or within the target environment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="simillar-project-title mb-50">
                                    <h2>You Also Might Like</h2>
                                </div>
                            </div>
                            <div className="portfolio-slider-wrapper" dir="ltr" id="related_portfolio">
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio1} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Artificial Intelligence</span>
                                                    <h3 className="title"><a href="javascript:void(0)" onClick={onProjectDetailAI}>Transformative Potential: Navigating the World of Artificial Intelligence in a Few Words.</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onProjectDetailAI}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio3} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Software Testing</span>
                                                    <h3 className="title"><a href="javascript:void(0)" onClick={onProjectDetailSoftwareTesting}>Immersive Experiences: Strategic Software Testing: Ensuring Quality and Reliability in Product Delivery</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onProjectDetailSoftwareTesting}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio5} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Web Development</span>
                                                    <h3 className="title"><a href="javascript:void(0)" onClick={onProjectDetailWebDev}>Innovation Unleashed: The Evolving Landscape of Web Development in the Modern Era.</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onProjectDetailWebDev}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>
    )
}

export default ApplicationDevelopmentDetail;