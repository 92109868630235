import React, { useEffect, useState } from 'react';

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import ProcessMethodology from '../../components/processMethodology';

// 
import LoderGif from '../../assets/images/icon/preeloder.gif'
import LetsTalk from '../../components/letsTalk';
import AllServices from '../../components/Services/allService';
// 

import service1 from '../../assets/images/brand-logo/client-1.png'
import service2 from '../../assets/images/brand-logo/client-2.png'
import service3 from '../../assets/images/brand-logo/client-3.png'
import service4 from '../../assets/images/brand-logo/client-4.png'
import service5 from '../../assets/images/brand-logo/client-5.png'
import service6 from '../../assets/images/brand-logo/client-6.png'
import service7 from '../../assets/images/brand-logo/client-7.png'
import service8 from '../../assets/images/brand-logo/client-8.png'
import service9 from '../../assets/images/brand-logo/client-9.png'
import service10 from '../../assets/images/brand-logo/client-10.png'
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';


const ServicePage = () => {
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }
            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Services</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <AllServices />

            <div class="brand-slider-area style-2">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title text-center justify-content-center">
                                <div class="sec-content">
                                    <span class="short-title">Services</span>
                                    <h2 class="title">Our Comprehensive Services</h2>
                                </div>
                            </div>
                            <div class="client-logo-slider-wrapper">
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service1} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service2} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service3} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service4} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service5} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service6} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service7} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service8} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service9} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="client-logo-wrapper">
                                        <div class="client-logo">
                                            <img src={service10} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ProcessMethodology />

            <LetsTalk />



            <FooterBar />

                        <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
            
        </div>
    );
};

export default ServicePage;
