import React, { useEffect, useState } from 'react';

import ServiceDetailMain from "../../assets/images/service-detail/service-details-technologyTraining-dev-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery-technologyTraining.jpg"
import outcome from "../../assets/images/service-detail/outcome-technologyTraining.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailTechnologyTraining = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Technology Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 order-1 service-details-wrapper">
                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="..." />
                                </div>
                                <div className="text">
                                    <p>Technology training is a dynamic and essential process that involves acquiring the knowledge and skills necessary to navigate the constantly evolving digital landscape. It encompasses a broad spectrum of topics, ranging from honing technical skills such as programming languages, database management, and web development to mastering the use of various software tools and project management platforms. Cybersecurity training is also a crucial component, including security awareness, ethical hacking, and incident response preparation. Cloud computing and data science training cover the intricacies of cloud platforms, serverless architecture, data analysis, and machine learning.</p>
                                    <p>Soft skills, such as effective communication, problem-solving, and adaptability, are equally emphasized. Pursuing industry certifications and fostering a culture of continuous learning are integral to staying current in the ever-changing tech environment. Customized training programs tailored to specific roles and organizational needs further enhance the efficacy of technology training, making it an indispensable investment for individuals and organizations aiming to remain competitive in the technology sector.</p>
                                </div>
                            </div>
                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Key Factors</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="..." />
                                                </div>
                                                <h4 className="title">Relevance and Customization</h4>
                                            </div>
                                            <div className="content">
                                                <p>Tailoring training programs to the specific needs of individuals, teams, or organizations ensures that the content is relevant and directly applicable to real-world scenarios.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="..." />
                                                </div>
                                                <h4 className="title">Instructor Quality</h4>
                                            </div>
                                            <div className="content">
                                                <p>Competent and experienced instructors play a vital role in delivering effective technology training. Their ability to communicate complex concepts, provide guidance, and facilitate discussions enhances the overall learning experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="..." />
                                                </div>
                                                <h4 className="title">Continuous Learning Culture</h4>
                                            </div>
                                            <div className="content">
                                                <p>Fostering a culture that values continuous learning encourages individuals and organizations to stay updated on the latest technologies and industry trends. This adaptability is crucial in the fast-paced world of technology.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="..." />
                                                </div>
                                                <h4 className="title">Accessibility and Flexibility</h4>
                                            </div>
                                            <div className="content">
                                                <p>Providing training through various mediums, such as online courses, workshops, or on-site sessions, ensures accessibility for diverse learning preferences. Flexibility in scheduling and delivery methods accommodates different learning styles.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Benefits</h4>
                                        <p>Implementing effective technology training programs offers numerous benefits for individuals, teams, and organizations in the rapidly evolving digital landscape:</p>
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Technology training equips individuals with up-to-date skills and competencies, making them more proficient in using the latest tools, programming languages, and technologies relevant to their roles.                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Well-trained individuals are more efficient in their tasks, leading to increased productivity. Automation of repetitive tasks and optimization of workflows are common outcomes of technology training.                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Training programs contribute to improved job performance by providing individuals with the knowledge and confidence to tackle challenges and fulfill their responsibilities effectively.                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Technology training fosters adaptability by preparing individuals to embrace and navigate changes in the technological landscape. This adaptability is crucial in industries where advancements occur rapidly.                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Outcome</h4>
                                        <p>Key outcomes that can result from well-implemented technology training programs:</p>
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Participants gain a deeper understanding and proficiency in the technologies and tools relevant to their roles, transforming them into subject matter experts.                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Training enhances problem-solving skills, enabling individuals to analyze and address technical challenges more efficiently, ultimately leading to faster issue resolution.                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Technology training stimulates innovative thinking and creativity, empowering individuals to explore new solutions, implement best practices, and contribute to continuous improvement.                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Teams benefit from streamlined workflows and efficient project execution as trained members apply their knowledge to optimize processes and deliver high-quality results within specified timelines.                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title">What is technology training?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Technology training involves acquiring knowledge and skills related to various technologies to enhance professional capabilities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title">Why is continuous learning important in the tech industry?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Continuous learning is crucial in the tech industry to stay updated on evolving technologies and remain competitive.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">How does technology training benefit organizations?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Technology training enhances employee skills, boosts productivity, and contributes to innovation, providing a competitive edge.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">What are some popular programming languages for technology professionals?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Python, Java, JavaScript, and C++ are widely used programming languages.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <LetsTalk />



            <FooterBar />

            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>

        </div>
    );
};

export default ServiceDetailTechnologyTraining;
