import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//
import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
//

import aboutcardimage from '../../assets/images/about/about-card-img.jpg';
import awardicon from '../../assets/images/icon/award.png';

//
import sliderimage1 from '../../assets/images/slider/user/user-1.jpg'
import sliderimage2 from '../../assets/images/slider/user/user-2.jpg'
import sliderimage3 from '../../assets/images/slider/user/user-3.jpg'
import sliderimage from '../../assets/images/slider/slider-1.jpg'
//

//
import featureicon1 from '../../assets/images/icon/feature-one/feature-1.png'
import featureicon2 from '../../assets/images/icon/feature-one/feature-2.png'
import featureicon3 from '../../assets/images/icon/feature-one/feature-3.png'
//

// 
import ImgPortfolio1 from '../../assets/images/portfolio/portfolio-tab/p1.jpg'
import ImgPortfolio2 from '../../assets/images/portfolio/portfolio-tab/p2.jpg'
import ImgPortfolio3 from '../../assets/images/portfolio/portfolio-tab/p3.jpg'
import ImgPortfolio4 from '../../assets/images/portfolio/portfolio-tab/p4.jpg'
import QuoteSlider from '../../components/Slider/quoteSlider';
import FeaturedProjects from '../../components/featuredProjects';
import StayUpdatedBlog from '../../components/Blogs/stayUpdatedBlog';
import FaqArea from '../../components/faqArea';
// 

//
import LoderGif from '../../assets/images/icon/preeloder.gif'
import LetsTalk from '../../components/letsTalk';
import AllServices from '../../components/Services/allService';
// 


const HomePage = () => {

    const navigate = useNavigate();

    const onAboutUs = (e) => {
        e.preventDefault();
        navigate('/about');
        window.location.reload();
    }
    const onContactUs = (e) => {
        e.preventDefault();
        navigate('/contact');
        window.location.reload();
    }

    const onServicePage = (e) => {
        e.preventDefault();
        navigate('/service');
        window.location.reload();
    }


    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);





    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }


            <TopNavBar />

            <div className="slider-area style-1">
                <div className="slider-wrapper">
                    <div className="single-slider-wrapper">
                        <div className="single-slider">
                            <div className="slider-overlay"></div>
                            <div className="container h-100 align-self-center">
                                <div className="row h-100">
                                    <div className="col-12">
                                        <div className="slider-content-wrapper">
                                            <div className="slider-content">
                                                <h1 className="slider-title">Digital * <br /> Innovation</h1>
                                                <p className="slider-short-desc">We are empowering businesses globally with cutting-edge IT solutions. Our advanced software and professional consulting services guarantee client contentment by delivering efficient resolutions to any IT obstacle business proprietors face worldwide.</p>
                                                <div className="slider-btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onAboutUs} className="style-btn">About Us<i className="fa fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slider-image-wrapper">
                                            <div className="animate-move-left-right">
                                                <div className="trusted-user">
                                                    <div className="user-list-wrapper">
                                                        <div className="single-user">
                                                            <img src={sliderimage1} alt="user" />
                                                        </div>
                                                        <div className="single-user">
                                                            <img src={sliderimage2} alt="user" />
                                                        </div>
                                                        <div className="single-user">
                                                            <img src={sliderimage3} alt="user" />
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <span className="counter-title">
                                                            <span className="counter-desc">
                                                                <p>Trusted By <br /> <span>2K<sup>+</sup></span> People</p>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slider-image">
                                                <img src={sliderimage} alt="feature image" />
                                            </div>
                                            <div className="company-award animate-move-top-bottom">
                                                <span className="icon">
                                                    <img src={awardicon} alt="image" />
                                                </span>

                                                <span className="title">Award <br /> Winning <br /> Agency</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="brand-marquee-area style-1">
                <div className="brand-marquee-wrapper first-brand-marquee pb-2">
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Digital</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Modern</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Digital</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Modern</span>
                        <span className="brand-marquee-star">❄️</span>
                    </div>
                </div>
            </div>

            <div className="feature-area style-1">
                <div className="container">
                    <div className="row gx-6">
                        <div className="col-12">
                            <div className="section-title">
                                <div className="sec-content">
                                    <span className="short-title">Welcome to TkTechnico </span>
                                    <h2 className="title">Empowering <br /> Technology</h2>
                                </div>
                                <div className="sec-desc">
                                    <p>Empowering enterprises worldwide with cutting-edge IT innovations, we strive to uplift businesses to new heights. Our advanced software solutions and seasoned advisory services are tailored to guarantee client contentment, delivering optimal resolutions to diverse IT hurdles encountered by businesses across the globe.</p>
                                    <a href="javascript:void(0)" onClick={onAboutUs} className="simple-btn">More About us <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="info-card ">
                                <div className="info-card-inner">
                                    <div className="content-wrapper">
                                        <div className="title-wrapper">
                                            <div className="icon">
                                                <img src={featureicon1} alt="icon" />
                                            </div>
                                            <h2 className="title">Innovative Approach</h2>
                                        </div>
                                        <div className="content">
                                            <p className="desc">Our team excels with a unique problem-solving approach, blending creativity and ingenuity. Committed to excellence, we consistently strive for greatness in every aspect of our work.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="info-card ">
                                <div className="info-card-inner">
                                    <div className="content-wrapper">
                                        <div className="title-wrapper">
                                            <div className="icon">
                                                <img src={featureicon2} alt="icon" />
                                            </div>
                                            <h2 className="title">Driving Innovative Solutions</h2>
                                        </div>
                                        <div className="content">
                                            <p className="desc">At the core of our mission lies a steadfast commitment to pioneering inventive solutions that surmount obstacles, catalysing progress, and fostering success.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="info-card ">
                                <div className="info-card-inner">
                                    <div className="content-wrapper">
                                        <div className="title-wrapper">
                                            <div className="icon">
                                                <img src={featureicon3} alt="icon" />
                                            </div>
                                            <h2 className="title">Efficient Real-Time Support</h2>
                                        </div>
                                        <div className="content">
                                            <p className="desc">Committed to real-time support, ensuring swift issue resolution, enhancing user experiences, and maximizing customer satisfaction through prompt concern resolution.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-us-area style-1">
                <div className="container">
                    <div className="row position-relative">




                        <div className="col-xxl-6 col-xl-5 col-lg-6">
                            <div className="about-image-card">

                                <div className="main-img-wrapper">
                                    <div className="main-img-inner">
                                        <img className="" src={aboutcardimage} alt="about card img" />
                                    </div>
                                    <div className="video-popup-btn">
                                        <div className="circle-rotate-wrapper">
                                            <div className="circle-rotate-inner">
                                                <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                    play video to know more about us
                                                </div>
                                            </div>
                                        </div>
                                        <a href="https://drive.google.com/file/d/1AaTwCOm1Ras9cNiD2_a3j6uYYg28R9t3/preview" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* <div className="col-xxl-6 col-xl-5 col-lg-6  d-none d-md-block">
                            <div className="about-image-card">

                                <div className="main-img-wrapper">
                                    <div className="main-img-inner">
                                        <img className="" src={aboutcardimage} alt="about card img" />
                                    </div>
                                    <div className="video-popup-btn">
                                        <div className="circle-rotate-wrapper">
                                            <div className="circle-rotate-inner">
                                                <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                    play video to know more about us
                                                </div>
                                            </div>
                                        </div>
                                        <a href="https://www.youtube.com/embed/dkxI_EWVP2s?si=GytDheKEakGlct1L&autoplay=1" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>*/}


                        {/* <div className="col-xxl-6 col-xl-5 col-lg-6  d-block d-sm-none">
                            <div className="about-image-card">

                                <div className="main-img-wrapper">
                                    <div className="main-img-inner">
                                        <img className="" src={aboutcardimage} alt="about card img" />
                                    </div>
                                    <div className="video-popup-btn">
                                        <div className="circle-rotate-wrapper">
                                            <div className="circle-rotate-inner">
                                                <div className="circle-rotate-text" data-circle-text='{"radius": 90, "forceWidth": true,"forceHeight": true}'>
                                                    play video to know more about us
                                                </div>
                                            </div>
                                        </div>
                                        <a href="https://www.youtube.com/embed/dkxI_EWVP2s?si=GytDheKEakGlct1L&autoplay=1&mute=1" className="mfp-iframe video-play">
                                            <i className="fa-solid fa-play" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}









                        <div className="col-xxl-6 col-xl-7 col-lg-6">
                            <div className="about-info-card style-1">
                                <div className="about-info-content">
                                    <div className="section-title">
                                        <div className="sec-content">
                                            <span className="short-title">Our Story</span>
                                            <h2 className="title">Resolving IT Challenges</h2>
                                        </div>
                                    </div>
                                    <div className="desc-wrapper">
                                        <p>TkTechnico Solutions is not just a software company; it's your strategic IT partner. Our mission is to deliver comprehensive IT solutions to businesses on the rise. With a specialised focus on engineering excellence and top-notch IT services, we pave the way for your success.</p>
                                        <p>In addition to our expertise in information technology and on-premise engineering consulting, our core services encompass cloud migration and modernization, comprehensive data management solutions, and efficient information management strategies.</p>
                                    </div>
                                    <div className="btn-wrapper">
                                        <a href="javascript:void(0)" onClick={onContactUs} className="simple-btn">Get in Touch<i className="fa fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AllServices />


            <div className="portfolio-slider-area style-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <div className="sec-content">
                                    <span className="short-title">Portfolio</span>
                                    <h2 className="title">Transform Your Visions <br /> into Reality </h2>
                                </div>
                                <div className="sec-desc">
                                    <a href="javascript:void(0)" onClick={onServicePage} className="theme-btn">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="portfolio-fullscreen">
                    <div className="portfolio-main-wrapper">
                        <div className="single-portfolio-item active" data-tab="tab-1">
                            <div className="portfolio-cat-wrapper">
                                <span className="portfolio-cat">RESEARCH</span>
                            </div>
                            <div className="portfolio-content">
                                <h3 className="title">Metaverse</h3>
                            </div>
                        </div>
                        <div className="single-portfolio-item" data-tab="tab-2">
                            <div className="portfolio-cat-wrapper">
                                <span className="portfolio-cat">Development</span>
                            </div>
                            <div className="portfolio-content">
                                <h3 className="title">Application Development</h3>
                            </div>
                        </div>
                        <div className="single-portfolio-item" data-tab="tab-3">
                            <div className="portfolio-cat-wrapper">
                                <span className="portfolio-cat">Consultancy</span>
                            </div>
                            <div className="portfolio-content">
                                <h3 className="title">Corporate Consultancy</h3>
                            </div>
                        </div>
                        <div className="single-portfolio-item" data-tab="tab-4">
                            <div className="portfolio-cat-wrapper">
                                <span className="portfolio-cat">Development</span>
                            </div>
                            <div className="portfolio-content">
                                <h3 className="title">AI Development</h3>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-fullscreen-image">
                        <div
                            id="tab-1"
                            className="tabimg active"
                            data-bg="images/portfolio/portfolio-tab/p1.jpg"
                            style={{ backgroundImage: `url(${ImgPortfolio1})` }}
                        >
                        </div>
                        <div
                            id="tab-2"
                            className="tabimg"
                            data-bg="images/portfolio/portfolio-tab/p2.jpg"
                            style={{ backgroundImage: `url(${ImgPortfolio2})` }}
                        >
                        </div>
                        <div
                            id="tab-3"
                            className="tabimg"
                            data-bg="images/portfolio/portfolio-tab/p3.jpg"
                            style={{ backgroundImage: `url(${ImgPortfolio3})` }}
                        >
                        </div>
                        <div
                            id="tab-4"
                            className="tabimg"
                            data-bg="images/portfolio/portfolio-tab/p4.jpg"
                            style={{ backgroundImage: `url(${ImgPortfolio4})` }}
                        >
                        </div>
                    </div>
                </div>
            </div>


            <FaqArea />

            {/* <div className="brand-marquee-area style-2">
                <div className="brand-marquee-wrapper first-brand-marquee">
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Branding</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">UI/UX</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Web Design</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                </div>
                <div className="brand-marquee-wrapper second-brand-marquee pb-2">
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Web Design</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Marketing</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">E-Commerce</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Creative</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                    <div className="single-brand-marquee">
                        <span className="brand-marquee-title">Smart</span>
                        <span className="brand-marquee-star">*</span>
                    </div>
                </div>
            </div> */}

            <FeaturedProjects />

            {/* <QuoteSlider /> */}

            <StayUpdatedBlog />

            <LetsTalk />

            <FooterBar />

            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>

        </div>
    )

};

export default HomePage;
