import React, { useEffect, useState } from 'react';
import portfolioP1 from '../assets/images/portfolio/p1.png'
import portfolioP2 from '../assets/images/portfolio/p2.jpg'
import portfolioP3 from '../assets/images/portfolio/p3.png'
import portfolioP4 from '../assets/images/portfolio/p4.jpg'
import { useNavigate } from 'react-router-dom';


const FeaturedProjects = () => {

    const navigate = useNavigate();

    const onProjectDetailAppDev = (e) => {
        e.preventDefault();
        navigate('/projectdetail-applicationDevelopment');
        window.location.reload();
    }
    const onProjectDetailWebDev = (e) => {
        e.preventDefault();
        navigate('/projectdetail-webDevelopment');
        window.location.reload();
    }
    const onProjectDetailSoftwareTesting = (e) => {
        e.preventDefault();
        navigate('/projectdetail-softwareTesting');
        window.location.reload();
    }
    const onProjectDetailAI = (e) => {
        e.preventDefault();
        navigate('/projectdetail');
        window.location.reload();
    }

     
    return (
        <div>

        <div className="portfolio-area style-2">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <div className="sec-content">
                                <span className="short-title">Projects</span>
                                <h2 className="title">Check Our Featured <br /> Projects</h2>
                            </div>
                            {/* <div className="sec-desc">
                                <a href="javascript:void(0)" className="theme-btn">View All</a>
                            </div> */}
                        </div>
                        <div className="portfolio-wrapper">
                            <div className="portfolio-card">
                                <span className="counter-number">01</span>
                                <div className="content">
                                    <span className="short-title">Application Design</span>
                                    <h2 className="title"><a href="javascript:void(0)"  onClick={onProjectDetailAppDev}>Application Development</a></h2>

                                    <div className="image">
                                        <img src={portfolioP1} alt="Image" />
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <a href="javascript:void(0)" className="style-btn" onClick={onProjectDetailAppDev}>View<i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="portfolio-card">
                                <span className="counter-number">02</span>
                                <div className="content">
                                    <span className="short-title">Web Design</span>
                                    <h2 className="title"><a href="javascript:void(0)" onClick={onProjectDetailWebDev}>Web Development</a></h2>

                                    <div className="image">
                                        <img src={portfolioP2} alt="Image" />
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <a href="javascript:void(0)" className="style-btn" onClick={onProjectDetailWebDev}>View<i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="portfolio-card">
                                <span className="counter-number">03</span>
                                <div className="content">
                                    <span className="short-title">Manual & Automation</span>
                                    <h2 className="title"><a href="javascript:void(0)" onClick={onProjectDetailSoftwareTesting}>Software Testing</a></h2>

                                    <div className="image">
                                        <img src={portfolioP3} alt="Image" />
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <a href="javascript:void(0)" className="style-btn" onClick={onProjectDetailSoftwareTesting}>View<i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="portfolio-card">
                                <span className="counter-number">04</span>
                                <div className="content">
                                    <span className="short-title">Web Design</span>
                                    <h2 className="title"><a href="javascript:void(0)" onClick={onProjectDetailAI}>AI Development</a></h2>

                                    <div className="image">
                                        <img src={portfolioP4} alt="Image" />
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <a href="javascript:void(0)" className="style-btn" onClick={onProjectDetailAI}>View<i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )

};

export default FeaturedProjects;
