import React, { useEffect, useState } from 'react';

import ServiceDetailMain from "../../assets/images/service-detail/service-details-cloud-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery-cloud.jpg"
import outcome from "../../assets/images/service-detail/outcome-cloud.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import { useNavigate } from 'react-router-dom';
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailProductDevlopment = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    const navigate = useNavigate();

    const onServiceDetailAI = (e) => {
        e.preventDefault();
        navigate('/service-detail-ai');
        window.location.reload();
    }

    const onServiceDetailFullStackSquad = (e) => {
        e.preventDefault();
        navigate('/service-detail-FullStackSquad');
        window.location.reload();
    }

    const onServiceDetailProductDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-productDevelopment');
        window.location.reload();
    }

    const onServiceDetailApplicationDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-applicationDevlopment');
        window.location.reload();
    }

    const onServiceDetailMetaVerse = (e) => {
        e.preventDefault();
        navigate('/service-detail-MetaVerse');
        window.location.reload();
    }

       // CONTACT FORM

       const [fullName, setFullName] = useState("");
       const [email, setEmail] = useState("");
       const [phoneNumber, setPhoneNumber] = useState("");
       const [subjectMail, setSubjectMail] = useState("");
       const [message, setMessage] = useState("");
   
   
       const [validfullName, setValidfullName] = useState(true);
       const [validEmail, setValidEmail] = useState(true);
       const [validPhoneNumber, setValidPhoneNumber] = useState(true);
       const [validSubjectMail, setValidSubjectMail] = useState(true);
       const [validMessage, setValidMessage] = useState(true);
   
       const [emailSent, setEmailSent] = useState(false);
   
   
       const onFullName = (e) => {
           setFullName(e.target.value);
           if (e.target.value === "") {
               setValidfullName(false);
           } else {
               setValidfullName(true);
           }
       }
   
       const onEmail = (e) => {
           setEmail(e.target.value);
           setValidEmail(validateEmail(e.target.value))
       }
   
       const onChangePhoneNumber = (e) => {
           let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
           setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
           if (e.target.value === '') {
               setValidPhoneNumber(false);
           } else {
               setValidPhoneNumber(true);
           }
       }
   
       const onSubjectMail = (e) => {
           setSubjectMail(e.target.value);
           if (e.target.value === '') {
               setValidSubjectMail(false);
           } else {
               setValidSubjectMail(true);
           }
       }
   
       const onMessage = (e) => {
           setMessage(e.target.value);
           if (e.target.value === '') {
               setValidMessage(false);
           } else {
               setValidMessage(true);
           }
       }
   
       const [submitDisabled, setSubmitDisabled] = useState(false);
   
       async function onContactUsSubmit(e) {
           e.preventDefault();
   
           setSubmitDisabled(true);
   
           fullName === '' && setValidfullName(false)
           email === '' && setValidEmail(false)
           phoneNumber === '' && setValidPhoneNumber(false)
           subjectMail === '' && setValidSubjectMail(false)
           message === '' && setValidMessage(false)
   
   
           if (fullName && email && phoneNumber && subjectMail && message && validEmail) {
               const options = {
                   method: 'POST',
                   headers: { 'Content-Type': 'application/json' },
                   body: JSON.stringify({
                       email: email,
                       name: fullName,
                       phoneNumber: phoneNumber,
                       subjectMail: subjectMail,
                       message: message
                   })
               };
   
               await fetch('https://localhost:44338/api/EmailEnquiry', options)
                   .then(response => response.json())
                   .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
                   .catch(err => console.error(err));
           }
           setSubmitDisabled(false);
       }
   
   
       const formatPhoneNumber = (phoneNumberString) => {
           var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
           cleaned = cleaned.slice(0, 10);
           var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
           if (match) {
               return '' + match[1] + ' ' + match[2] + ' ' + match[3];
           }
           return phoneNumberString.toString();
       }
   
       const validateEmail = (mail) => {
           if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
               return true
           }
           return false
       }
   
       // CONTACT FORM
   

    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Product Development</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 order-1 service-details-wrapper">


                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="service-details" />
                                </div>
                                <div className="text">
                                    <p>
                                        Our Product Concept & Design phase stands as the cornerstone of our robust product development lifecycle. We specialize in collaborating closely with your product management team and end customers, working diligently to conceptualize business requirements and craft detailed specifications and solutions.
                                    </p>
                                    <p>
                                        At TkTechnico, we recognize that product design is not just about creating something tangible; it's about bringing an idea to life. It holds immense significance for any organization, determining the success or failure of a product and directly impacting the company's business outcomes, market share, and overall reputation. Our dedicated Product Concept & Design team consists of a blend of skilled professionals, including Business Analysts, Usability Specialists, Industry experts, and Technocrats.
                                    </p>
                                    <p>
                                        We understand that the transformation of an idea into a reality requires a multifaceted approach, and our diverse team is equipped to navigate the intricacies of the product design journey. By leveraging the expertise of our team members, we ensure that your product not only meets but exceeds the expectations of both your internal stakeholders and end customers.
                                    </p>
                                </div>
                            </div>


                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Advantages of Our Approach</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 className="title">In-depth exploration</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    We conduct thorough research, focusing efforts professionally on specific software or solution issues.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Back Engineering</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    We deliver optimal technology recommendations through the practice of reverse engineering.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Mock-up Creation</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Our usability and heuristic analysis seamlessly aid in the facilitation of prototyping.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Deployment</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    The systematic implementation and release of a newly developed product into the market or operational environment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Top Highlights</h4>
                                        {/* <p>Here are four key benefits of a well-executed deployment process in Cloud Technology:</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Embrace agile methods for adaptable, responsive product development with iterative improvements and quick response to changing requirements.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Leverage cutting-edge tech to keep the IT service website innovative, delivering modern and efficient solutions to users.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Prioritize user-centric design for enhanced website usability, contributing to customer satisfaction and engagement.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Ensure a secure IT service platform with robust security measures, safeguarding sensitive data and building user trust.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Outcome</h4>
                                        {/* <p>Here are four key outcomes that result from a successful product development:</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Successful product development results in the creation of innovative solutions that address specific market needs or challenges.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Optimize user experience with a well-developed product, focusing on usability, functionality, and overall satisfaction.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Ensure product development aligns with market demands for sustained relevance and competitiveness.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Effective product development optimizes internal processes, streamlining workflows, and enhancing efficiency in the development lifecycle.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title">What is product development?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        Product development is the process of conceptualizing, designing, and bringing a new product to the market.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title">How do you initiate the product development process?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        The process begins with ideation and market research to identify opportunities and user needs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">What factors are considered during product development?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        Factors include market demand, competition, cost, feasibility, and technological requirements.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">How long does the product development cycle typically take?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        The duration varies, but it generally involves stages like ideation, design, development, testing, and deployment, spanning months to years.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="col-xl-3 order-2 order-xl-1">
                            <div class="service-sidebar">
                                <div class="widget widget_service_categories">
                                    <ul>
                                        <li>
                                            {/* <a href="#"><i class="fa-solid fa-brain"></i>AI Development</a> */}
                                            <a href="#" onClick={onServiceDetailAI}>AI Development</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailFullStackSquad}>Full Stack Squad</a>
                                        </li>
                                        <li class="active">
                                            <a href="#" onClick={onServiceDetailProductDevlopment}>Product Development</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailApplicationDevlopment}>Application Development</a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={onServiceDetailMetaVerse}>Metaverse</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_contact_form">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Get A Quote</h4>
                                    </div>

                                    {emailSent ?
                                        <>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                                <div className="thank-you-heading">
                                                    Thank You, {fullName}
                                                </div>
                                                <div className="thank-you-sub-heading">
                                                    Our team will be in touch with you soon.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div class="comment-respond">
                                            <form action="#" method="post" class="comment-form">
                                                <div class="row gx-2">
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="author" type="text" placeholder="Your name*"
                                                                className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" value={fullName} onChange={e => onFullName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-email">
                                                            <input name="email" type="text" placeholder="Your email*"
                                                                className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" value={email} onChange={e => onEmail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="phone" type="text" placeholder="Your phone"
                                                                className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" value={phoneNumber} onChange={e => onChangePhoneNumber(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="website" type="text" placeholder="Subject"
                                                                className={`form-control ${!validSubjectMail && 'is-invalid'}`} id="exampleFormControlInput1" value={subjectMail} onChange={e => onSubjectMail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-message">
                                                            <textarea name="comment" cols="20" rows="3" placeholder="Start writing your message"
                                                                className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" value={message} onChange={e => onMessage(e)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        {

                                                            !submitDisabled ?
                                                                <button className="theme-btn" type="submit" onClick={onContactUsSubmit} disabled={submitDisabled}>Submit Now</button>
                                                                :

                                                                <div className="loader">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>

                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }

                                </div>
                                <div class="widget widget_service_promotion">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Need Help?</h4>
                                    </div>
                                    <div class="promotion-card">
                                        <p class="short-desc">Your digital success, our priority. Innovative IT solutions for seamless business evolution.</p>
                                        <a href="tel:+919960803355" class="secondary-btn">
                                            <span class="icon">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <div class="btn-text">
                                                <p>Call Us</p>
                                                <h4>+919960803355</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <LetsTalk />



            <FooterBar />

                            <div id="scrollTop" class="scrollup-wrapper">
                    <div class="scrollup-btn">
                        <i class="fa fa-angle-up"></i>
                    </div>
                </div>
            
        </div>
    );
};

export default ServiceDetailProductDevlopment;
