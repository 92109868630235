import React, { useEffect, useState } from 'react';

import ServiceDetailMain from "../../assets/images/service-detail/service-details-app-dev-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery-app-dev.jpg"
import outcome from "../../assets/images/service-detail/outcome-app-dev.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import { useNavigate } from 'react-router-dom';
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailApplicationDevlopment = () => {


    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    const navigate = useNavigate();

    const onServiceDetailAI = (e) => {
        e.preventDefault();
        navigate('/service-detail-ai');
        window.location.reload();
    }

    const onServiceDetailFullStackSquad = (e) => {
        e.preventDefault();
        navigate('/service-detail-FullStackSquad');
        window.location.reload();
    }

    const onServiceDetailProductDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-productDevelopment');
        window.location.reload();
    }

    const onServiceDetailApplicationDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-applicationDevlopment');
        window.location.reload();
    }

    const onServiceDetailMetaVerse = (e) => {
        e.preventDefault();
        navigate('/service-detail-MetaVerse');
        window.location.reload();
    }

       // CONTACT FORM

       const [fullName, setFullName] = useState("");
       const [email, setEmail] = useState("");
       const [phoneNumber, setPhoneNumber] = useState("");
       const [subjectMail, setSubjectMail] = useState("");
       const [message, setMessage] = useState("");
   
   
       const [validfullName, setValidfullName] = useState(true);
       const [validEmail, setValidEmail] = useState(true);
       const [validPhoneNumber, setValidPhoneNumber] = useState(true);
       const [validSubjectMail, setValidSubjectMail] = useState(true);
       const [validMessage, setValidMessage] = useState(true);
   
       const [emailSent, setEmailSent] = useState(false);
   
   
       const onFullName = (e) => {
           setFullName(e.target.value);
           if (e.target.value === "") {
               setValidfullName(false);
           } else {
               setValidfullName(true);
           }
       }
   
       const onEmail = (e) => {
           setEmail(e.target.value);
           setValidEmail(validateEmail(e.target.value))
       }
   
       const onChangePhoneNumber = (e) => {
           let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
           setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
           if (e.target.value === '') {
               setValidPhoneNumber(false);
           } else {
               setValidPhoneNumber(true);
           }
       }
   
       const onSubjectMail = (e) => {
           setSubjectMail(e.target.value);
           if (e.target.value === '') {
               setValidSubjectMail(false);
           } else {
               setValidSubjectMail(true);
           }
       }
   
       const onMessage = (e) => {
           setMessage(e.target.value);
           if (e.target.value === '') {
               setValidMessage(false);
           } else {
               setValidMessage(true);
           }
       }
   
       const [submitDisabled, setSubmitDisabled] = useState(false);
   
       async function onContactUsSubmit(e) {
           e.preventDefault();
   
           setSubmitDisabled(true);
   
           fullName === '' && setValidfullName(false)
           email === '' && setValidEmail(false)
           phoneNumber === '' && setValidPhoneNumber(false)
           subjectMail === '' && setValidSubjectMail(false)
           message === '' && setValidMessage(false)
   
   
           if (fullName && email && phoneNumber && subjectMail && message && validEmail) {
               const options = {
                   method: 'POST',
                   headers: { 'Content-Type': 'application/json' },
                   body: JSON.stringify({
                       email: email,
                       name: fullName,
                       phoneNumber: phoneNumber,
                       subjectMail: subjectMail,
                       message: message
                   })
               };
   
               await fetch('https://localhost:44338/api/EmailEnquiry', options)
                   .then(response => response.json())
                   .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
                   .catch(err => console.error(err));
           }
           setSubmitDisabled(false);
       }
   
   
       const formatPhoneNumber = (phoneNumberString) => {
           var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
           cleaned = cleaned.slice(0, 10);
           var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
           if (match) {
               return '' + match[1] + ' ' + match[2] + ' ' + match[3];
           }
           return phoneNumberString.toString();
       }
   
       const validateEmail = (mail) => {
           if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
               return true
           }
           return false
       }
   
       // CONTACT FORM



    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Application Development</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 order-1 service-details-wrapper">
                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="service-details" />
                                </div>
                                <div className="text">
                                    <p>Welcome to our Service Page at TkTechnico, where innovation meets functionality through our expert application development services. At TkTechnico, we pride ourselves on crafting cutting-edge applications that seamlessly integrate with your business objectives. Our experienced team of developers at TkTechnico utilizes the latest technologies to create robust and user-friendly applications tailored to meet your unique requirements. Whether you're looking for a sleek mobile app, a powerful web application, or a customized software solution, TkTechnico has got you covered. From conceptualization to deployment, our streamlined development process at TkTechnico ensures a smooth and efficient journey, delivering high-quality applications that enhance user experience and drive business success.</p>
                                    <p>At the heart of TkTechnico's application development approach is a commitment to understanding your business needs and translating them into scalable and efficient solutions. We at TkTechnico focus on creating intuitive interfaces, optimizing performance, and implementing security measures to guarantee a seamless user experience. With a proven track record of delivering successful applications across various industries, TkTechnico takes pride in our collaborative approach, transparent communication, and agile development methodology. Elevate your digital presence and empower your business with TkTechnico's application development expertise – where innovation meets functionality, and your vision becomes reality.</p>
                                </div>
                            </div>
                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Key Factors</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Application Development Research</h4>
                                            </div>
                                            <div className="content">
                                                <p>Application development research is a dynamic field that continually explores emerging technologies and methodologies to create innovative, efficient, and user-centric software solutions. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Risk Factor Analysis</h4>
                                            </div>
                                            <div className="content">
                                                <p>Risk factor analysis is a comprehensive process that involves identifying, assessing, and mitigating potential risks that may impact a project, business, or initiative. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">System Development</h4>
                                            </div>
                                            <div className="content">
                                                <p>System development refers to the process of creating, designing, implementing, and maintaining complex systems, which could include software applications, hardware infrastructure, or a combination of both.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Deployment</h4>
                                            </div>
                                            <div className="content">
                                                <p>Deployment in the context of software development refers to the process of releasing a software application or system for use in a specific environment.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Benefits</h4>
                                        {/* <p>Here are four key benefits of a well-executed deployment process in software development:</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Efficient deployment strategies, such as phased rollouts or canary releases, can help minimize downtime and disruptions to end-users.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Thorough testing and monitoring during and after deployment enable the identification and resolution of performance issues and bugs.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    A smooth and well-communicated deployment process contributes to enhanced user satisfaction and acceptance of the new software.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    In the event of unforeseen issues or critical errors post-deployment, having a rollback plan allows the development team to revert to the previous version quickly.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Outcome</h4>
                                        {/* <p>Here are four key outcomes that result from a successful software deployment:</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Deployed software boosts operational efficiency, enabling users to benefit from new features and streamlined workflows for increased productivity.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Successful deployment means that end-users experience minimal disruptions and can seamlessly transition to the updated version of the software.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    The successful deployment of a software application aligns with the business objectives and goals that prompted its development.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    A successful deployment marks a milestone in the software development lifecycle, but it also sets the stage for continuous improvement.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title">What is the significance of a phased rollout in software deployment?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>A phased rollout is a deployment strategy where the new software version is released incrementally to different user groups or geographic regions. This approach helps identify and address issues in a controlled manner, minimizing the impact of potential problems. It allows for gradual user adaptation, reduces the risk of widespread disruptions, and enables the development team to gather valuable feedback for continuous improvement.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title">Why is a rollback plan important in software deployment?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>A rollback plan is essential to quickly revert to the previous version of the software in case unforeseen issues or critical errors arise post-deployment. This plan provides a safety net, allowing the development team to address problems promptly without extended downtime. Having a well-defined rollback strategy contributes to the overall reliability and resilience of the deployment process.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">How can communication be optimized during software deployment to minimize user concerns?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Clear and transparent communication is crucial during software deployment. Providing stakeholders, end-users, and support teams with a detailed deployment schedule, potential downtime, and any changes in functionality helps manage expectations. Regular updates before, during, and after deployment, along with user training sessions, contribute to a smoother transition and alleviate user concerns.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">What role does testing play in the deployment process, and why is it important?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Testing is a critical component of the deployment process as it helps identify and address potential issues before the software is released to production. Unit testing, system testing, and user acceptance testing ensure that the application functions as intended and meets user expectations. Thorough testing contributes to improved stability, performance, and overall quality of the deployed software.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 order-2 order-xl-1">
                            <div class="service-sidebar">
                                <div class="widget widget_service_categories">
                                    <ul>
                                        <li>
                                            {/* <a href="#"><i class="fa-solid fa-brain"></i>AI Development</a> */}
                                            <a href="#" onClick={onServiceDetailAI}>AI Development</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailFullStackSquad}>Full Stack Squad</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailProductDevlopment}>Product Development</a>
                                        </li>
                                        <li class="active">
                                            <a href="#"onClick={onServiceDetailApplicationDevlopment}>Application Development</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailMetaVerse}>Metaverse</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_contact_form">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Get A Quote</h4>
                                    </div>

                                    {emailSent ?
                                        <>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                                <div className="thank-you-heading">
                                                    Thank You, {fullName}
                                                </div>
                                                <div className="thank-you-sub-heading">
                                                    Our team will be in touch with you soon.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div class="comment-respond">
                                            <form action="#" method="post" class="comment-form">
                                                <div class="row gx-2">
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="author" type="text" placeholder="Your name*"
                                                                className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" value={fullName} onChange={e => onFullName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-email">
                                                            <input name="email" type="text" placeholder="Your email*"
                                                                className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" value={email} onChange={e => onEmail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="phone" type="text" placeholder="Your phone"
                                                                className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" value={phoneNumber} onChange={e => onChangePhoneNumber(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="website" type="text" placeholder="Subject"
                                                                className={`form-control ${!validSubjectMail && 'is-invalid'}`} id="exampleFormControlInput1" value={subjectMail} onChange={e => onSubjectMail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-message">
                                                            <textarea name="comment" cols="20" rows="3" placeholder="Start writing your message"
                                                                className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" value={message} onChange={e => onMessage(e)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        {

                                                            !submitDisabled ?
                                                                <button className="theme-btn" type="submit" onClick={onContactUsSubmit} disabled={submitDisabled}>Submit Now</button>
                                                                :

                                                                <div className="loader">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>

                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }

                                </div>
                                <div class="widget widget_service_promotion">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Need Help?</h4>
                                    </div>
                                    <div class="promotion-card">
                                        <p class="short-desc">Your digital success, our priority. Innovative IT solutions for seamless business evolution.</p>
                                        <a href="tel:+919960803355" class="secondary-btn">
                                            <span class="icon">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <div class="btn-text">
                                                <p>Call Us</p>
                                                <h4>+919960803355</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            {/* <LetsTalk /> */}



            <FooterBar />

                        <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
            
        </div>
    );
};

export default ServiceDetailApplicationDevlopment;
