import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import latestpost1 from '../../assets/images/latest-posts/lp1.jpg'
import latestpost2 from '../../assets/images/latest-posts/lp2.jpg'
import latestpost3 from '../../assets/images/latest-posts/lp3.jpg'
import latestpostfeature from '../../assets/images/latest-posts/lpfeature-1.jpg'

const StayUpdatedBlog = () => {

    const navigate = useNavigate();

    const onBlog = (e) => {
        e.preventDefault();
        navigate('/blog');
        window.location.reload();
    }
    const onBlogDetailDigitalLandscape = (e) => {
        e.preventDefault();
        navigate('/blogdetail-digitalLandscape');
        window.location.reload();
    }
    const onBlogDetailCodingJourney = (e) => {
        e.preventDefault();
        navigate('/blogdetail-codingJourney');
        window.location.reload();
    }
    const onBlogDetailCloudComputing = (e) => {
        e.preventDefault();
        navigate('/blogdetail-cloudComputing');
        window.location.reload();
    }
    const onBlogDetailStrategiesBuilding = (e) => {
        e.preventDefault();
        navigate('/blogdetail-strategiesBuilding');
        window.location.reload();
    }


    return (
        <div className="latest-posts-area style-1">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <div className="sec-content">
                                <span className="short-title">Stay Updated</span>
                                <h2 className="title">Latest News & Updates <br /> from Our Blog</h2>
                            </div>
                            <div className="sec-desc">
                                <a href="javascript:void(0)" onClick={onBlog} className="theme-btn">View All</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="post-card style-2">
                            <div className="image">
                                <img src={latestpostfeature} alt="post-1" />
                                <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape} className="btn-link">
                                    <i className="fa fa-arrow-right"></i>
                                </a>
                            </div>
                            <div className="content">
                                <span className="post-cat"><a href="javascript:void(0)">Technology</a></span>
                                <h2 className="title">
                                    <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape}>Safeguarding Data in the Digital Landscape: A Deep Dive into Privacy and Protection</a>
                                </h2>
                                <div className="post-meta">
                                    <span className="single-post-meta">
                                        <i className="fa fa-clock"></i>
                                        <span>February 06, 2024</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="post-card-wrapper">
                            <div className="post-card style-1">
                                <div className="image">
                                    <img src={latestpost1} alt="post-1" />
                                    <a href="javascript:void(0)" onClick={onBlogDetailCodingJourney} className="btn-link">
                                        <i className="fa fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <span className="post-cat"><a href="javascript:void(0)">Technology</a></span>
                                    <h2 className="title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailCodingJourney}>Insights and Innovations in the IT</a>
                                    </h2>
                                    <div className="post-meta">
                                        <span className="single-post-meta">
                                            <i className="fa fa-clock"></i>
                                            <span>January 16, 2024</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="post-card style-1">
                                <div className="image">
                                    <img src={latestpost2} alt="post-1" />
                                    <a href="javascript:void(0)" onClick={onBlogDetailCloudComputing} className="btn-link">
                                        <i className="fa fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <span className="post-cat"><a href="javascript:void(0)">Technology</a></span>
                                    <h2 className="title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailCloudComputing}>Navigating the Skies: Cloud Computing as the Beacon for the Future of IT</a>
                                    </h2>
                                    <div className="post-meta">
                                        <span className="single-post-meta">
                                            <i className="fa fa-clock"></i>
                                            <span>January 01, 2024</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="post-card style-1">
                                <div className="image">
                                    <img src={latestpost3} alt="post-1" />
                                    <a href="javascript:void(0)" onClick={onBlogDetailStrategiesBuilding} className="btn-link">
                                        <i className="fa fa-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <span className="post-cat"><a href="javascript:void(0)">Technology</a></span>
                                    <h2 className="title">
                                        <a href="javascript:void(0)" onClick={onBlogDetailStrategiesBuilding}>E-Commerce Excellence: Unveiling Strategies for Building a Thriving Online Store</a>
                                    </h2>
                                    <div className="post-meta">
                                        <span className="single-post-meta">
                                            <i className="fa fa-clock"></i>
                                            <span>December 12, 2023</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default StayUpdatedBlog;
