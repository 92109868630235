import React, { useEffect, useState } from 'react';

import ServiceDetailMain from "../../assets/images/service-detail/service-details-main-metaverse.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery-metaverse.jpg"
import outcome from "../../assets/images/service-detail/outcome-metaverse.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import { useNavigate } from 'react-router-dom';
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailMetaVerse = () => {


    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    const navigate = useNavigate();
    
    const onServiceDetailAI = (e) => {
        e.preventDefault();
        navigate('/service-detail-ai');
        window.location.reload();
    }

    const onServiceDetailFullStackSquad = (e) => {
        e.preventDefault();
        navigate('/service-detail-FullStackSquad');
        window.location.reload();
    }

    const onServiceDetailProductDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-productDevelopment');
        window.location.reload();
    }

    const onServiceDetailApplicationDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-applicationDevlopment');
        window.location.reload();
    }

    const onServiceDetailMetaVerse = (e) => {
        e.preventDefault();
        navigate('/service-detail-MetaVerse');
        window.location.reload();
    }
    
       // CONTACT FORM

       const [fullName, setFullName] = useState("");
       const [email, setEmail] = useState("");
       const [phoneNumber, setPhoneNumber] = useState("");
       const [subjectMail, setSubjectMail] = useState("");
       const [message, setMessage] = useState("");
   
   
       const [validfullName, setValidfullName] = useState(true);
       const [validEmail, setValidEmail] = useState(true);
       const [validPhoneNumber, setValidPhoneNumber] = useState(true);
       const [validSubjectMail, setValidSubjectMail] = useState(true);
       const [validMessage, setValidMessage] = useState(true);
   
       const [emailSent, setEmailSent] = useState(false);
   
   
       const onFullName = (e) => {
           setFullName(e.target.value);
           if (e.target.value === "") {
               setValidfullName(false);
           } else {
               setValidfullName(true);
           }
       }
   
       const onEmail = (e) => {
           setEmail(e.target.value);
           setValidEmail(validateEmail(e.target.value))
       }
   
       const onChangePhoneNumber = (e) => {
           let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
           setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
           if (e.target.value === '') {
               setValidPhoneNumber(false);
           } else {
               setValidPhoneNumber(true);
           }
       }
   
       const onSubjectMail = (e) => {
           setSubjectMail(e.target.value);
           if (e.target.value === '') {
               setValidSubjectMail(false);
           } else {
               setValidSubjectMail(true);
           }
       }
   
       const onMessage = (e) => {
           setMessage(e.target.value);
           if (e.target.value === '') {
               setValidMessage(false);
           } else {
               setValidMessage(true);
           }
       }
   
       const [submitDisabled, setSubmitDisabled] = useState(false);
   
       async function onContactUsSubmit(e) {
           e.preventDefault();
   
           setSubmitDisabled(true);
   
           fullName === '' && setValidfullName(false)
           email === '' && setValidEmail(false)
           phoneNumber === '' && setValidPhoneNumber(false)
           subjectMail === '' && setValidSubjectMail(false)
           message === '' && setValidMessage(false)
   
   
           if (fullName && email && phoneNumber && subjectMail && message && validEmail) {
               const options = {
                   method: 'POST',
                   headers: { 'Content-Type': 'application/json' },
                   body: JSON.stringify({
                       email: email,
                       name: fullName,
                       phoneNumber: phoneNumber,
                       subjectMail: subjectMail,
                       message: message
                   })
               };
   
               await fetch('https://localhost:44338/api/EmailEnquiry', options)
                   .then(response => response.json())
                   .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
                   .catch(err => console.error(err));
           }
           setSubmitDisabled(false);
       }
   
   
       const formatPhoneNumber = (phoneNumberString) => {
           var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
           cleaned = cleaned.slice(0, 10);
           var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
           if (match) {
               return '' + match[1] + ' ' + match[2] + ' ' + match[3];
           }
           return phoneNumberString.toString();
       }
   
       const validateEmail = (mail) => {
           if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
               return true
           }
           return false
       }
   
       // CONTACT FORM
   

    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Continuum of the Metaverse</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 order-1 service-details-wrapper">
                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="service-details" />
                                </div>
                                <div className="text">
                                    <p>
                                        Embark on a new era of digital evolution with our IT services. At our core is a distinctive perspective: the metaverse as a continuum, a spectrum enriching digital worlds, realities, and business models. Over the next decade, it will reshape every aspect of life and business, enabling collaboration in virtual spaces, augmenting physical environments, and seamlessly blending both realms. This transformative shift will open doors to new business lines and redefine customer-company interactions.
                                    </p>
                                    <p>
                                        In the current landscape, businesses are racing towards a future unlike their original design. Every company is approaching the intersection of various new worlds, whether constructing physical and virtual realities or delivering services within environments crafted by others.
                                    </p>
                                    <p>
                                        To thrive in this fast-changing world, our strategies prioritize responsibility. From data ownership to fostering inclusion and diversity, promoting sustainability, ensuring security, and prioritizing personal safety, we guide you in navigating this transformative journey.
                                    </p>
                                    <p>
                                        Now is the opportune moment for visionary thinking, commencing with small steps and swiftly scaling. Seize the moment to adapt and thrive with our IT solutions tailored for this dynamic digital landscape.
                                    </p>
                                </div>
                            </div>
                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Capabilities within the Metaverse</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Enterprise-Scale Innovation</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Facilitating our clients in conceiving, crafting, and delivering expansive extended reality (XR) experiences tailored for enterprise-scale impact.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Virtual Identity</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Transitioning from physical to digital realms, we oversee access to systems and data throughout the organization, ensuring end-to-end zero-trust security.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Technological Advancement</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Formulate a lasting vision, design appropriate solutions, and ensure your investments contribute to forging a competitive advantage in the future.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Tech Metamorphosis</h4>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Redirect your attention from traditional to transformative technology, expediting the pace of change delivery.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Benefits</h4>
                                        {/* <p>AI boosts efficiency, automates tasks, enhances decision-making, and unlocks insights, driving innovation and growth for businesses.</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Boost customer engagement with immersive metaverse experiences for lasting impressions and stronger connections.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Enable global collaboration in the metaverse, transcending borders for seamless team and client interactions in virtual environments.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Innovate product showcases through dynamic virtual demonstrations, offering interactive experiences for potential customers.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Enhance training with immersive metaverse programs, providing realistic and interactive learning experiences for skill acquisition.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Outcome</h4>
                                        {/* <p>The outcomes of AI implementation are diverse and impact various aspects of businesses and society, including:</p> */}
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Enhance user experience with immersive digital environments, elevating overall engagement with our services.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Unlock business growth with metaverse services, tapping into innovative ways to reach a broader audience.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Metaverse fosters improved business collaboration, communication, teamwork, and innovation.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Forge a cutting-edge brand presence with metaverse services, showcasing commitment to innovation and tech trends.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title">What is the Metaverse?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        The Metaverse is a collective virtual shared space that encompasses the sum of all virtual worlds, augmented reality, and the internet. It offers immersive, interactive experiences and often involves social collaboration.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title">How does the Metaverse enhance user experiences?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        The Metaverse enhances user experiences by providing immersive and interactive digital environments, allowing users to engage with content, interact with others, and participate in various activities.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">What benefits does the Metaverse offer for businesses?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        Businesses can leverage the Metaverse for enhanced customer engagement, global collaboration, innovative product demonstrations, and immersive training programs, opening up new opportunities and improving brand presence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">How can the Metaverse be utilized for virtual product demonstrations?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>
                                                        The Metaverse enables businesses to showcase products through virtual demonstrations and simulations, allowing users to experience offerings in dynamic and interactive environments, leading to more engaging and informative experiences.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-3 order-2 order-xl-1">
                            <div class="service-sidebar">
                                <div class="widget widget_service_categories">
                                    <ul>
                                        <li>
                                            {/* <a href="#"><i class="fa-solid fa-brain"></i>AI Development</a> */}
                                            <a href="#" onClick={onServiceDetailAI}>AI Development</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailFullStackSquad}>Full Stack Squad</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailProductDevlopment}>Product Development</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailApplicationDevlopment}>Application Development</a>
                                        </li>
                                        <li class="active">
                                            <a href="#"onClick={onServiceDetailMetaVerse}>Metaverse</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_contact_form">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Get A Quote</h4>
                                    </div>

                                    {emailSent ?
                                        <>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                                <div className="thank-you-heading">
                                                    Thank You, {fullName}
                                                </div>
                                                <div className="thank-you-sub-heading">
                                                    Our team will be in touch with you soon.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div class="comment-respond">
                                            <form action="#" method="post" class="comment-form">
                                                <div class="row gx-2">
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="author" type="text" placeholder="Your name*"
                                                                className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" value={fullName} onChange={e => onFullName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-email">
                                                            <input name="email" type="text" placeholder="Your email*"
                                                                className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" value={email} onChange={e => onEmail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="phone" type="text" placeholder="Your phone"
                                                                className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" value={phoneNumber} onChange={e => onChangePhoneNumber(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="website" type="text" placeholder="Subject"
                                                                className={`form-control ${!validSubjectMail && 'is-invalid'}`} id="exampleFormControlInput1" value={subjectMail} onChange={e => onSubjectMail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-message">
                                                            <textarea name="comment" cols="20" rows="3" placeholder="Start writing your message"
                                                                className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" value={message} onChange={e => onMessage(e)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        {

                                                            !submitDisabled ?
                                                                <button className="theme-btn" type="submit" onClick={onContactUsSubmit} disabled={submitDisabled}>Submit Now</button>
                                                                :

                                                                <div className="loader">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>

                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }

                                </div>
                                <div class="widget widget_service_promotion">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Need Help?</h4>
                                    </div>
                                    <div class="promotion-card">
                                        <p class="short-desc">Your digital success, our priority. Innovative IT solutions for seamless business evolution.</p>
                                        <a href="tel:+919960803355" class="secondary-btn">
                                            <span class="icon">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <div class="btn-text">
                                                <p>Call Us</p>
                                                <h4>+919960803355</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <LetsTalk /> */}



            <FooterBar />
                        <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
            

        </div>
    );
};

export default ServiceDetailMetaVerse;
