import React from 'react';
import { useNavigate } from 'react-router-dom';
import FooterLogo from '../../assets/images/logo/footer-logo.png'


const FooterBar = () => {

    const navigate = useNavigate();

    const onHomePage = (e) => {
        e.preventDefault();
        navigate('/');
        window.location.reload();
    }
    const onAboutUs = (e) => {
        e.preventDefault();
        navigate('/about');
        window.location.reload();
    }
    const onServicePage = (e) => {
        e.preventDefault();
        navigate('/service');
        window.location.reload();
    }
    const onBlog = (e) => {
        e.preventDefault();
        navigate('/blog');
        window.location.reload();
    }
    const onContactUs = (e) => {
        e.preventDefault();
        navigate('/contact');
        window.location.reload();
    }
    const onTermsAndConditions = (e) => {
        e.preventDefault();
        navigate('/TermsAndConditions');
        window.location.reload();
    }
    const onPrivacyPolicy = (e) => {
        e.preventDefault();
        navigate('/PrivacyPolicy');
        window.location.reload();
    }


    return (
        <div>
            <footer className="footer style-1">
                <div className="footer-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <img src={FooterLogo} onClick={onHomePage} alt="logo" />
                                        <span className="bevan-regular-footer" onClick={onHomePage}>TKTECHNICO</span>
                                    </div>
                                    <div className="subscribe-form-wrapper">
                                        <p>We propel businesses with advanced IT solutions, ensuring client satisfaction globally.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row justify-content-lg-center widget-menu-wrapper">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="footer-widget widget_nav_menu">
                                            <h2 className="footer-widget-title">Company</h2>
                                            <ul>
                                                <li><a href="javascript:void(0)" onClick={onServicePage}>Service</a></li>
                                                <li><a href="javascript:void(0)" onClick={onBlog}>Blog</a></li>
                                                <li><a href="javascript:void(0)" onClick={onAboutUs}>About us</a></li>
                                                <li><a href="javascript:void(0)" onClick={onContactUs}>Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3">
                                <div className="footer-widget">
                                    <h2 className="footer-widget-title">Address</h2>
                                    <div className="footer-widget-info">
                                        <div className="footer-widget-contact">
                                            <div className="footer-contact">
                                                <ul>
                                                    <li>
                                                        <div className="contact-icon">
                                                            <i className="fa fa-location-dot"></i>
                                                        </div>
                                                        <div className="contact-text">
                                                            <span>A-504, Kool Homes Arena, Patil Nagar, Balewadi, Pune, Maharashtra, 411045, IN</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="contact-icon">
                                                            <i className="fa fa-phone-volume"></i>
                                                        </div>
                                                        <div className="contact-text">
                                                            <a href="tel:+919960803355">+919960803355</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="contact-icon">
                                                            <i className="fa fa-envelope"></i>
                                                        </div>
                                                        <div className="contact-text">
                                                            <a href="mailto:info@tktechnico.com"><span>info@tktechnico.com</span></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-bottom-wrapper">
                                    <div className="footer-bottom-menu-wrapper">
                                        <div className="copyright-text">
                                            <p>All Rights Reserved © 2021 TkTechnico</p>
                                        </div>
                                        <div className="footer-bottom-menu">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0)" onClick={onTermsAndConditions}>Terms & Condition</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)" onClick={onPrivacyPolicy}>Privacy Policy</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="social-profile">
                                        <a href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                        <a href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        <a href="javascript:void(0)"><i className="fa-brands fa-youtube"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default FooterBar;
