import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceDetailMain from "../../assets/images/service-detail/service-details-app-dev-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery-app-dev.jpg"
import outcome from "../../assets/images/service-detail/outcome-app-dev.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailFullStackSquad = () => {


    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);
    
    const navigate = useNavigate();
    
    const onServiceDetailAI = (e) => {
        e.preventDefault();
        navigate('/service-detail-ai');
        window.location.reload();
    }

    const onServiceDetailFullStackSquad = (e) => {
        e.preventDefault();
        navigate('/service-detail-FullStackSquad');
        window.location.reload();
    }

    const onServiceDetailProductDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-productDevelopment');
        window.location.reload();
    }

    const onServiceDetailApplicationDevlopment = (e) => {
        e.preventDefault();
        navigate('/service-detail-applicationDevlopment');
        window.location.reload();
    }

    const onServiceDetailMetaVerse = (e) => {
        e.preventDefault();
        navigate('/service-detail-MetaVerse');
        window.location.reload();
    }

   // CONTACT FORM

   const [fullName, setFullName] = useState("");
   const [email, setEmail] = useState("");
   const [phoneNumber, setPhoneNumber] = useState("");
   const [subjectMail, setSubjectMail] = useState("");
   const [message, setMessage] = useState("");


   const [validfullName, setValidfullName] = useState(true);
   const [validEmail, setValidEmail] = useState(true);
   const [validPhoneNumber, setValidPhoneNumber] = useState(true);
   const [validSubjectMail, setValidSubjectMail] = useState(true);
   const [validMessage, setValidMessage] = useState(true);

   const [emailSent, setEmailSent] = useState(false);


   const onFullName = (e) => {
       setFullName(e.target.value);
       if (e.target.value === "") {
           setValidfullName(false);
       } else {
           setValidfullName(true);
       }
   }

   const onEmail = (e) => {
       setEmail(e.target.value);
       setValidEmail(validateEmail(e.target.value))
   }

   const onChangePhoneNumber = (e) => {
       let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
       setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
       if (e.target.value === '') {
           setValidPhoneNumber(false);
       } else {
           setValidPhoneNumber(true);
       }
   }

   const onSubjectMail = (e) => {
       setSubjectMail(e.target.value);
       if (e.target.value === '') {
           setValidSubjectMail(false);
       } else {
           setValidSubjectMail(true);
       }
   }

   const onMessage = (e) => {
       setMessage(e.target.value);
       if (e.target.value === '') {
           setValidMessage(false);
       } else {
           setValidMessage(true);
       }
   }

   const [submitDisabled, setSubmitDisabled] = useState(false);

   async function onContactUsSubmit(e) {
       e.preventDefault();

       setSubmitDisabled(true);

       fullName === '' && setValidfullName(false)
       email === '' && setValidEmail(false)
       phoneNumber === '' && setValidPhoneNumber(false)
       subjectMail === '' && setValidSubjectMail(false)
       message === '' && setValidMessage(false)


       if (fullName && email && phoneNumber && subjectMail && message && validEmail) {
           const options = {
               method: 'POST',
               headers: { 'Content-Type': 'application/json' },
               body: JSON.stringify({
                   email: email,
                   name: fullName,
                   phoneNumber: phoneNumber,
                   subjectMail: subjectMail,
                   message: message
               })
           };

           await fetch('https://localhost:44338/api/EmailEnquiry', options)
               .then(response => response.json())
               .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
               .catch(err => console.error(err));
       }
       setSubmitDisabled(false);
   }


   const formatPhoneNumber = (phoneNumberString) => {
       var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
       cleaned = cleaned.slice(0, 10);
       var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
       if (match) {
           return '' + match[1] + ' ' + match[2] + ' ' + match[3];
       }
       return phoneNumberString.toString();
   }

   const validateEmail = (mail) => {
       if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
           return true
       }
       return false
   }
   // CONTACT FORM


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Full Stack Squad</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 order-1 service-details-wrapper">
                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="service-details" />
                                </div>
                                <div className="text">
                                    <p>A full stack development squad is a cross-functional team comprising skilled professionals capable of handling both front-end and back-end aspects of software development. These squads typically include developers proficient in various programming languages, frameworks, and tools for creating an intuitive user interface as well as implementing server-side logic and managing databases. The collaborative nature of these squads allows them to efficiently navigate the entire software development process, ensuring seamless integration and functionality across the entire technology stack.</p>
                                    <p>Comprising skilled developers, designers, and possibly other specialists, the squad navigates the entire technology stack seamlessly. From crafting user-friendly interfaces to implementing server-side logic and managing databases, the squad collaborates closely to deliver holistic solutions. In agile project settings, these squads operate efficiently on specific features or components, ensuring rapid development cycles and comprehensive problem-solving. This agile structure enhances adaptability, responsiveness, and the ability to produce well-integrated software solutions across diverse technological domains. These squads collaborate closely, leveraging their diverse skill sets to address challenges holistically, from user experience design to database management. This approach facilitates faster development cycles, quick iteration, and the delivery of comprehensive solutions within the scope of their designated responsibilities.</p>
                                </div>
                            </div>
                            
                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Key Factors</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Diverse Skill Set</h4>
                                            </div>
                                            <div className="content">
                                                <p>Ensure team members possess a balanced combination of front-end, back-end, and DevOps skills to cover the full spectrum of web development responsibilities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Collaboration and Communication</h4>
                                            </div>
                                            <div className="content">
                                                <p>Emphasize strong interpersonal and communication skills, facilitating effective collaboration within the squad and promoting clear communication with other teams and stakeholders.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Problem-Solving Skills</h4>
                                            </div>
                                            <div className="content">
                                                <p>Cultivate problem-solving capabilities by encouraging a proactive and analytical approach to troubleshooting and issue resolution.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="icon" />
                                                </div>
                                                <h4 className="title">Continuous Learning</h4>
                                            </div>
                                            <div className="content">
                                                <p>Foster a culture of continuous learning within the squad, encouraging team members to seek out opportunities for skill enhancement and staying informed about industry advancements.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="widget widget_tag_cloud">
                            <div className="key-featured-area">
                            <h1 className="key-featured-title">
                            Recruit Comprehensive Development Team             
                            </h1>
                            <div class="single-post-meta">
                                <div class="blog-post-tag">
                            <div class="tagcloud">
                                <a>Flexible Hiring Models</a>
                                <a>NDA protected contract</a>
                                <a>IP-Rights Protection</a>
                                <a>Timezone Compatibility</a>
                                <a>Fast & Easy onboarding</a>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>

                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Benefits</h4>
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Full stack squads enhance communication, collaboration, and productivity by integrating frontend, backend, and other specialists.                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    These squads streamline development tasks, from UI design to deployment, accelerating release cycles and time-to-market.                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Aligned with agile methodologies, full stack squads adapt quickly to changing requirements and pivoting when necessary.                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Diverse skill sets enable holistic problem-solving across the entire application stack, rather than isolated frontend or backend issues.                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Why Choose the Full Stack Squad?</h4>
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Full Stack Squads require developers skilled in both front-end and back-end technologies.                                                </li>
                                                <li>    
                                                    <i className="fa fa-check"></i>
                                                    These squads promote collaboration and communication across diverse development aspects.                                                </li>
                                                <li>       
                                                    <i className="fa fa-check"></i>
                                                    Full-stack developers are valued for their adaptability to varied tasks and technologies.                                                </li>
                                                <li> 
                                                    <i className="fa fa-check"></i>
                                                    Streamlining communication speeds up decision-making, improves project understanding, and enhances development efficiency.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title"> What is a Full Stack Squad?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>A Full Stack Squad is a cross-functional team comprising developers, designers, and other specialists capable of handling both front-end and back-end development tasks. This setup enables the team to work on entire features independently.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title">What Skills are Essential for a Full Stack Squad Member?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Full Stack Squad members should possess proficiency in both front-end technologies (HTML, CSS, JavaScript, etc.) and back-end technologies (Node.js, Python, Java, etc.). Additionally, they should be familiar with databases, version control, and deployment processes.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">How Does Collaboration Work in a Full Stack Squad?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Collaboration is essential. Team members share responsibilities and collaborate closely throughout the development process. Regular communication ensures a holistic understanding of the project, fostering a collaborative environment.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">What Tools and Technologies are Commonly Used by Full Stack Squads?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Tools and technologies can vary, but common ones include Git for version control, databases such as MySQL or MongoDB, and frameworks like React or Angular for front-end development. For back-end, Node.js, Django, or Spring are popular choices.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  
                        <div class="col-xl-3 order-2 order-xl-1">
                            <div class="service-sidebar">
                                <div class="widget widget_service_categories">
                                    <ul>
                                        <li>
                                            {/* <a href="#"><i class="fa-solid fa-brain"></i>AI Development</a> */}
                                            <a href="#" onClick={onServiceDetailAI}>AI Development</a>
                                        </li>
                                        <li class="active">
                                            <a href="#"onClick={onServiceDetailFullStackSquad}>Full Stack Squad</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailProductDevlopment}>Product Development</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailApplicationDevlopment}>Application Development</a>
                                        </li>
                                        <li>
                                            <a href="#"onClick={onServiceDetailMetaVerse}>Metaverse</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_contact_form">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Get A Quote</h4>
                                    </div>

                                    {emailSent ?
                                        <>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                                <div className="thank-you-heading">
                                                    Thank You, {fullName}
                                                </div>
                                                <div className="thank-you-sub-heading">
                                                    Our team will be in touch with you soon.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div class="comment-respond">
                                            <form action="#" method="post" class="comment-form">
                                                <div class="row gx-2">
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="author" type="text" placeholder="Your name*"
                                                                className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" value={fullName} onChange={e => onFullName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-email">
                                                            <input name="email" type="text" placeholder="Your email*"
                                                                className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" value={email} onChange={e => onEmail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="phone" type="text" placeholder="Your phone"
                                                                className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" value={phoneNumber} onChange={e => onChangePhoneNumber(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-name">
                                                            <input name="website" type="text" placeholder="Subject"
                                                                className={`form-control ${!validSubjectMail && 'is-invalid'}`} id="exampleFormControlInput1" value={subjectMail} onChange={e => onSubjectMail(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div className="contacts-message">
                                                            <textarea name="comment" cols="20" rows="3" placeholder="Start writing your message"
                                                                className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" value={message} onChange={e => onMessage(e)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        {

                                                            !submitDisabled ?
                                                                <button className="theme-btn" type="submit" onClick={onContactUsSubmit} disabled={submitDisabled}>Submit Now</button>
                                                                :

                                                                <div className="loader">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>

                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }

                                </div>
                                <div class="widget widget_service_promotion">
                                    <div class="widget-title-box">
                                        <h4 class="wp-block-heading">Need Help?</h4>
                                    </div>
                                    <div class="promotion-card">
                                        <p class="short-desc">Your digital success, our priority. Innovative IT solutions for seamless business evolution.</p>
                                        <a href="tel:+919960803355" class="secondary-btn">
                                            <span class="icon">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <div class="btn-text">
                                                <p>Call Us</p>
                                                <h4>+919960803355</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <LetsTalk /> */}



            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
            

        </div>
    );
};


export default ServiceDetailFullStackSquad;
