import React, { useEffect, useState } from 'react';

import ServiceDetailMain from "../../assets/images/service-detail/service-details-professionalConsultancy-main.jpg"
import benefitdelivery from "../../assets/images/service-detail/benefite-delivery-professionalConsultancy.jpg"
import outcome from "../../assets/images/service-detail/outcome-professionalConsultancy.jpg"

import ailogo from "../../assets/images/icon/key-featured/ai.png"
import padlocklogo from "../../assets/images/icon/key-featured/padlock.png"
import algorithmlogo from "../../assets/images/icon/key-featured/algorithm.png"
import robotlogo from "../../assets/images/icon/key-featured/robot.png"

import TopNavBar from '../../components/Navbars/topNavBar';
import FooterBar from '../../components/Navbars/footerBar';
import LetsTalk from '../../components/letsTalk';

// 

import LoderGif from '../../assets/images/icon/preeloder.gif'
import ContactButtonMobile from '../../components/contactButton/contactButtonMobile';




const ServiceDetailProfessionalConsultancy = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />


            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Professional Consultancy</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-details-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 order-1 service-details-wrapper">
                            <div className="details-content">
                                <div className="feature-image">
                                    <img src={ServiceDetailMain} alt="..." />
                                </div>
                                <div className="text">
                                    <p>Professional consultancy involves engaging experts, commonly known as consultants, to provide specialized advice and solutions in various domains. These consultants, possessing in-depth knowledge and experience, offer their expertise to individuals, businesses, or organizations facing specific challenges. Whether focusing on management, finance, marketing, IT, human resources, or other areas, consultants play a crucial role in problem-solving. They bring an objective perspective, often identifying overlooked issues and delivering unbiased recommendations. Consultancy services can be project-based or ongoing, and consultants tailor their solutions to meet the unique needs of their clients. They may assist in implementing proposed changes, making consultancy a cost-effective way to access specialized knowledge without long-term commitments. Confidentiality is a key aspect of consultancy agreements, ensuring the secure handling of sensitive business information. It aims to optimize decision-making, problem-solving, and organizational effectiveness through the application of external expertise.</p>
                                    <p>Professional consultancy is a collaborative engagement where highly skilled consultants lend their expertise to individuals, businesses, or organizations seeking targeted solutions to complex challenges. These consultants, often specialists in areas like management, finance, IT, marketing, and human resources, bring a wealth of knowledge cultivated through years of hands-on experience. The consultancy process typically begins with a thorough analysis of the client's current situation, identifying key issues, and formulating tailored recommendations.</p>
                                </div>
                            </div>
                            <div className="key-featured-area">
                                <h2 className="key-featured-title">Key Factors</h2>
                                <div className="row gx-8">
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={ailogo} alt="..." />
                                                </div>
                                                <h4 className="title">Expertise and Specialization</h4>
                                            </div>
                                            <div className="content">
                                                <p>The foundational factor is the consultants' deep expertise and specialization in a particular field. Their knowledge should be up-to-date and relevant to the client's industry or challenges.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={padlocklogo} alt="..." />
                                                </div>
                                                <h4 className="title">Objective Perspective</h4>
                                            </div>
                                            <div className="content">
                                                <p>Consultants bring an unbiased and objective viewpoint to the table, enabling them to identify issues and opportunities that may be overlooked by those within the organization.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={algorithmlogo} alt="..." />
                                                </div>
                                                <h4 className="title">Communication Skills</h4>
                                            </div>
                                            <div className="content">
                                                <p>Effective communication is crucial for consultants to convey complex ideas, insights, and recommendations clearly. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-key-featured">
                                            <div className="title-wrapper">
                                                <div className="icon">
                                                    <img src={robotlogo} alt="..." />
                                                </div>
                                                <h4 className="title">Analytical Skills</h4>
                                            </div>
                                            <div className="content">
                                                <p>Consultants must possess strong analytical skills to assess situations, identify patterns, and generate meaningful insights.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details-overview-area">
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={benefitdelivery} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Benefits</h4>
                                        <p>Professional consultancy offers a range of benefits for individuals, businesses, and organizations seeking specialized expertise and solutions: </p>
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Consultants bring in-depth knowledge and expertise in specific areas, providing clients with access to skills that may not be available in-house. This ensures a high level of competence and efficiency in addressing complex challenges.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    External consultants offer an impartial and unbiased viewpoint. This objectivity is crucial for identifying issues, evaluating opportunities, and making recommendations without being influenced by internal politics or biases.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Hiring consultants can be a cost-effective solution compared to recruiting and training full-time employees for short-term projects. It allows organizations to access specialized skills without the long-term financial commitment associated with permanent staff.
                                                </li>
                                                <li>
                                                    <i className="fa fa-angle-right"></i>
                                                    Consultancy services can be tailored to meet the specific needs of a project or initiative. Whether it's a short-term engagement or ongoing support, the flexibility of consultancy allows organizations to scale resources based on demand.                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-item">
                                    <div className="image">
                                        <img src={outcome} alt="..." />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Outcome</h4>
                                        <p>The outcomes of engaging in professional consultancy are multifaceted, contributing to organizational growth, efficiency, and strategic advancement. Here are the outcomes summarized:</p>
                                        <div className="list-item">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Implementation of efficient processes and strategies enhances overall operational effectiveness.                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Informed decision-making based on expert advice and objective insights.                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Strategic guidance aligns the organization with industry best practices, fostering competitiveness.                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    Timely and successful completion of projects with the support of streamlined processes.                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-area style-3">
                                <h4 className="faq-title">Frequently Asked Questions</h4>
                                <p></p>

                                <div className="accordion-wrapper style-one">
                                    <div className="accordion-box-wrapper" id="serviceDetailsFAQ">
                                        <div className="accordion-list-item">
                                            <div id="headingOne">
                                                <div className="accordion-head" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h3 className="accordion-title">What is the typical duration of a professional consultancy engagement?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Duration varies based on project nature and scope, ranging from short-term initiatives to long-term partnerships.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingTwo">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h3 className="accordion-title">How can professional consultancy benefit my business or organization?</h3>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>It benefits by offering access to specialized knowledge, optimizing operations, and providing strategic guidance for sustainable growth.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingThree">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h3 className="accordion-title">What criteria should I consider when selecting a professional consultant for my project?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Consider factors such as expertise, track record, and alignment with organizational values when selecting a consultant.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-list-item">
                                            <div id="headingFour">
                                                <div className="accordion-head collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h3 className="accordion-title">Can professional consultants provide references or case studies from similar projects they have worked on?</h3>
                                                    <div className="icon"></div>
                                                </div>
                                            </div>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#serviceDetailsFAQ">
                                                <div className="accordion-item-body">
                                                    <p>Yes, consultants can showcase experience and success through references and case studies, demonstrating their capabilities.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <LetsTalk />



            <FooterBar />

            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>

        </div>
    );
};

export default ServiceDetailProfessionalConsultancy;
