import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopNavBar from '../../components/Navbars/topNavBar'
import FooterBar from '../../components/Navbars/footerBar'

import blogdetailfeature from '../../assets/images/blog-detail/b2.jpg'
import blogimagerp1 from '../../assets/images/blog/rp-1.jpg'
import blogimagerp2 from '../../assets/images/blog/rp-2.jpg'
import blogimagerp3 from '../../assets/images/blog/rp-3.jpg'

import instapost1 from '../../assets/images/instagram/instagram-1.jpg'
import instapost2 from '../../assets/images/instagram/instagram-2.jpg'
import instapost3 from '../../assets/images/instagram/instagram-3.jpg'
import instapost4 from '../../assets/images/instagram/instagram-4.jpg'
import instapost5 from '../../assets/images/instagram/instagram-5.jpg'
import instapost6 from '../../assets/images/instagram/instagram-6.jpg'

import LoderGif from '../../assets/images/icon/preeloder.gif'

const BlogDetailCodingJourney = () => {

    const navigate = useNavigate();

    const onBlogDetailDigitalLandscape = (e) => {
        e.preventDefault();
        navigate('/blogdetail-digitalLandscape');
        window.location.reload();
    }
    const onBlogDetailCodingJourney = (e) => {
        e.preventDefault();
        navigate('/blogdetail-codingJourney');
        window.location.reload();
    }
    const onBlogDetailCloudComputing = (e) => {
        e.preventDefault();
        navigate('/blogdetail-cloudComputing');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }

            <TopNavBar />

            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Insights and Innovations in the IT</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 blog-details-wrapper">
                            <article className="single-post-item">
                                <div className="post-thumbnail">
                                    <a href="javascript:void(0)">
                                        <img src={blogdetailfeature} alt="Blog Image" />
                                    </a>
                                </div>
                                <div className="post-content-wrapper">
                                    <div className="post-meta">
                                        <span><i className="fa-solid fa-folder-open"></i>Technology</span>
                                        <span><i className="fa-solid fa-clock"></i>January 16, 2024</span>
                                    </div>
                                    <h3 className="post-title">
                                        <h3>Navigating the Digital Horizon: Insights and Innovations in the IT Business Landscape</h3>
                                    </h3>
                                    <div className="post-content">
                                        <p>
                                            Welcome to our IT business blog, where we delve into the dynamic world of technology, exploring the latest trends, strategies, and innovations shaping the future of IT enterprises. Join us on this journey as we unravel the complexities, share expert insights, and provide actionable advice to empower your business in the ever-evolving digital landscape.
                                        </p>
                                        <p>
                                            <strong>
                                                The Rise of Smart Tech: How IoT is Reshaping IT Businesses
                                            </strong>
                                            <br />
                                            Explore the transformative impact of the Internet of Things (IoT) on IT enterprises, from enhancing efficiency to unlocking new revenue streams. Learn how leading businesses are leveraging smart technologies to stay ahead in the game.
                                        </p>
                                        <p>
                                            <strong>
                                                Cloud Chronicles: Unraveling the Mysteries of Cloud Computing for IT Success
                                            </strong>
                                            <br />
                                            Dive deep into the world of cloud computing as we demystify its concepts, explore best practices, and discuss how cloud solutions are revolutionizing the way IT businesses operate, collaborate, and scale.
                                        </p>
                                        <p>
                                            <strong>
                                                Cybersecurity Unveiled: Fortifying Your IT Business Against Digital Threats
                                            </strong>
                                            <br />
                                            In an era of increasing cyber threats, this article explores the latest cybersecurity trends, risk mitigation strategies, and the imperative need for robust digital defenses to safeguard your IT business and client data.
                                        </p>
                                        <p>
                                            <strong>
                                                Agile Revolution: Adapting IT Business Strategies for a Rapidly Changing World
                                            </strong>
                                            <br />
                                            Discover the power of Agile methodologies in transforming IT business operations. Uncover success stories, implementation tips, and how adopting an agile mindset can drive innovation, flexibility, and client satisfaction.
                                        </p>
                                        <p>
                                            <strong>
                                                Data Goldmine: Leveraging Big Data for Business Intelligence in IT
                                            </strong>
                                            <br />
                                            Delve into the realm of big data analytics and its profound impact on IT businesses. Learn how harnessing the power of data can drive informed decision-making, enhance customer experiences, and fuel business growth.
                                        </p>
                                        <p>
                                            <strong>
                                                Digital Transformation Decoded: A Roadmap for IT Businesses
                                            </strong>
                                            <br />
                                            Embark on a journey of digital transformation, exploring key strategies, challenges, and success stories from IT businesses that have embraced and navigated the transformative process.
                                        </p>
                                        <p>
                                            <strong>
                                                Future-Proofing Your IT Business: Emerging Technologies on the Horizon
                                            </strong>
                                            <br />
                                            Anticipate the future of technology as we explore emerging trends such as AI, blockchain, and quantum computing. Gain insights into how these technologies are poised to shape the future landscape of IT businesses.
                                        </p>
                                        <p>
                                            <strong>
                                                Conclusion:
                                            </strong>
                                            <br />
                                            Our IT business blog is your go-to resource for staying informed, inspired, and ahead of the curve in the fast-paced world of technology. Join us on this exciting exploration of the latest trends, strategies, and innovations driving success in the IT business domain. Your journey to IT excellence starts here!
                                        </p>
                                    </div>
                                    <div className="single-post-meta">
                                        <div className="blog-post-tag">
                                            <span>TAGS</span>
                                            <div className="post-tag-list">
                                                <a>Chat GPT</a>
                                                <a>Google Bard</a>
                                                <a>Facebook</a>
                                                <a>Meta</a>
                                            </div>
                                        </div>
                                        <div className="social-share">
                                            <span className="social-share-title">SHARE</span>
                                            {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                            <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                            <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="sidebar">
                                <div className="widget widget_latest_post">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Recent Posts</h4>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp1} onClick={onBlogDetailDigitalLandscape} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="javascript:void(0)" onClick={onBlogDetailDigitalLandscape} >The Digital Landscape</a>

                                                </h3>
                                                <span className="latest-post-meta">February 06, 2024</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp2} onClick={onBlogDetailCodingJourney} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="javascript:void(0)" onClick={onBlogDetailCodingJourney} >Insights and Innovations in the IT</a>
                                                </h3>
                                                <span className="latest-post-meta">January 16, 2024</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-thumb">
                                                <img src={blogimagerp3} onClick={onBlogDetailCloudComputing} alt="" />
                                            </div>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title">
                                                    <a href="javascript:void(0)" onClick={onBlogDetailCloudComputing} >Cloud Computing The Future Of IT</a>
                                                </h3>
                                                <span className="latest-post-meta">January 01, 2024</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget_instagram_feed">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Gallery</h4>
                                    </div>
                                    <div className="widget-instagram-feed">
                                        <div className="single-instagram-feed">
                                            <img src={instapost1} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost2} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost3} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost4} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost5} alt="instagram photo" />
                                        </div>
                                        <div className="single-instagram-feed">
                                            <img src={instapost6} alt="instagram photo" />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_social_profile">
                                    <div className="widget-title-box">
                                        <h4 className="wp-block-heading">Follow Us</h4>
                                    </div>
                                    <div className="social-profile">
                                        {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                        <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                        <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                        <a className="instagram" href="javascript:void(0)"><i className="fa-brands fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>


    )
}

export default BlogDetailCodingJourney