import React, { useEffect, useState } from 'react';
import LoderGif from '../../assets/images/icon/preeloder.gif'

import FooterBar from '../../components/Navbars/footerBar';
import TopNavBar from '../../components/Navbars/topNavBar';

const TermsAndConditions = () => {

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>

            {showLoader && (
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            )}
            <TopNavBar />
            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Terms & Conditions</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>


                <strong>Terms &amp; Conditions</strong>
                <p>
                    This website and the information, tools, and materials contained in it (the "Site") are not intended for distribution or use by any person or entity in jurisdictions where such distribution, publication, availability, or use would be contrary to law or regulation, or would subject Tktechnico Solutions Pvt. Ltd. or its affiliates to any registration or licensing requirement within such jurisdictions.
                </p>
                <p>
                    TkTechnico Solutions Pvt. Ltd. is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve
                    the right to make changes to the app or to charge for its services, at any time and for any reason. We will never
                    charge you for the app or its services without making it very clear to you exactly what you’re paying for.
                </p>
                <p><strong>Limited License:</strong></p>
                <p>
                    Subject to the terms and conditions set forth in this Agreement, Tktechnico Solutions Pvt. Ltd. grants a non-exclusive, non-transferable, limited right to access this site and the materials thereon.
                </p>
                <p><strong>You hereby agree and confirm that:</strong></p>
                <p>
                    You hereby agree and confirm that:
                    <ul>
                        <li>
                            Access to this site and the information contained herein is not unlawful under the applicable laws of the jurisdiction where you reside and from where you are accessing this site.
                        </li>
                        <li>
                            Access to information on the site does not constitute an offer to sell or a solicitation of any offer to buy any securities of Tktechnico Solutions Pvt. Ltd.
                        </li>
                        <li>
                            The information on this site is not, and under no circumstances should be construed as, an advertisement or a public offering of the securities of Tktechnico Solutions Pvt. Ltd. or any other security described herein.
                        </li>
                        <li>
                            No securities regulatory body or similar authority in any jurisdiction has reviewed or endorsed the information on this site or the merits of the securities described herein.
                        </li>
                    </ul>
                </p>
                <p><strong>You agree not to:</strong></p>
                <p>
                    <ul>
                        <li>
                            Interrupt or attempt to interrupt the operation of the site in any way.
                        </li>
                        <li>
                            Intrude or attempt to intrude into the site in any way.
                        </li>
                        <li>
                            Post any obscene, defamatory, or annoying materials on the site.
                        </li>
                        <li>
                            Obscure any materials, including this notice, already posted on the site.
                        </li>
                        <li>
                            Use the site or any contents thereof to defame, intimidate, annoy, or otherwise cause a nuisance or breach the rights of any person.
                        </li>
                    </ul>
                </p>
                <p>
                    Tktechnico Solutions Pvt. Ltd. authorizes you to view and download the information ("Materials") on this website only for personal, non-commercial use. This authorization is not a transfer of title in the Materials and is subject to the following restrictions:
                </p>
                <ul>
                    <li>
                        Retain, on all copies of the Materials downloaded, all copyright, trademarks, and other proprietary notices contained in the Materials.
                    </li>
                    <li>
                        Not modify the Materials in any way nor reproduce, display, perform, or distribute them for any public or commercial purpose.
                    </li>
                    <li>
                        Not transfer the Materials to any other person without notifying them of, and obtaining their agreement to, the obligations arising under these terms and conditions.
                    </li>
                </ul>
                <p>
                    You agree to abide by all additional restrictions displayed on the site, as it may be updated from time to time. This site, including all Materials, is copyrighted and protected by worldwide copyright laws and treaty provisions. You agree to comply with all copyright laws worldwide in your use of this site and to prevent any unauthorized copying of the Materials.
                </p>
                <p>
                    The information, material, or services included in or available through this site may contain inaccuracies or typographical errors. Tktechnico Solutions Pvt. Ltd. and/or its respective suppliers may make improvements and/or changes to the site/services at any time.
                </p>
                <p>
                    You specifically agree that Tktechnico Solutions Pvt. Ltd. shall not be responsible for unauthorized access to or alteration of your transmissions or data, any material or data sent or received, or any transactions entered into through this site. Tktechnico Solutions Pvt. Ltd. is not responsible for any threatening, defamatory, obscene, offensive, or illegal content or conduct of any other party, or any infringement of another's rights, including intellectual property rights.
                </p>
                <p>
                    In no event shall Tktechnico Solutions Pvt. Ltd. and/or its suppliers be liable for any direct, indirect, punitive, incidental, special, or consequential damages or any damages whatsoever, including, without limitation, damages for loss of use, data, or profits, arising out of or in any way connected with the use or performance of this site/services.
                </p>
                <p>
                    These terms are subject to the laws of the Republic of India, and any disputes arising out of the use of this site shall have the exclusive jurisdiction of the Republic of India.
                </p>
                <p>
                    Please proceed only if you accept all the conditions enumerated hereinabove, out of your free will and consent.
                </p>
            </div>
            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
