import React, { useEffect, useState } from 'react';
import backgroundsectionimage from '../assets/images/section-bg/faq.jpg'
import companylogo from '../assets/images/icon/company-logo.png';
import { useNavigate } from 'react-router-dom';


const LetsTalk = () => {
    const navigate = useNavigate();

    const onContactUs = (e) => {
        e.preventDefault();
        navigate('/contact');
        window.location.reload();
    }

    return (
        <div className="cta-area style-1 background-blue">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="call-to-action-card">
                            <div className="content">
                                <span className="short-title">Have a Project in Mind?</span>
                                <h2 className="title">Let's Collaborate and Get Started Today!</h2>
                            </div>
                            <div className="btn-wrapper">
                                <a href="javascript:void(0)" onClick={onContactUs} className="style-btn">Let’s Talk<i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

};

export default LetsTalk;
