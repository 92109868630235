import React from 'react'
import { useEffect, useState } from 'react'
import TopNavBar from '../../components/Navbars/topNavBar'
import FooterBar from '../../components/Navbars/footerBar'
import LoderGif from '../../assets/images/icon/preeloder.gif'

import projectimg1 from '../../assets/images/project-detail/project-details-1-webDev.jpg'
import projectimg2 from '../../assets/images/project-detail/project-details-2-webDev.jpg'
import projectimg3 from '../../assets/images/project-detail/project-details-3-webDev.jpg'

import portfolio1 from '../../assets/images/project/p1.jpg'
import portfolio3 from '../../assets/images/project/p3.jpg'
import portfolio5 from '../../assets/images/project/p5.jpg'
import { useNavigate } from 'react-router-dom'


const WebDevelopmentDetail = () => {

    const navigate = useNavigate();

    const onProjectDetailAI = (e) => {
        e.preventDefault();
        navigate('/projectdetail');
        window.location.reload();
    }

    const onProjectDetailAppDev = (e) => {
        e.preventDefault();
        navigate('/projectdetail-applicationDevelopment');
        window.location.reload();
    }

    const onProjectDetailSoftwareTesting = (e) => {
        e.preventDefault();
        navigate('/projectdetail-softwareTesting');
        window.location.reload();
    }

    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div>
            {showLoader &&
                <div id="preloader">
                    <div
                        id="preloader-status"
                        style={{ backgroundImage: `url(${LoderGif})` }}
                    ></div>
                </div>
            }
            <TopNavBar />
            <div className="page-breadcrumb-area page-bg">
                <div className="page-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper">
                                <div className="page-heading">
                                    <h3 className="page-title">Product Delivery for Web Development</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="project-detail-area">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-12 col-lg-8">
                            <div className="project-gallery-image">
                                <img src={projectimg1} alt="project-details" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="d-flex flex-column gap-4">
                                <div className="project-gallery-image">
                                    <img src={projectimg2} alt="project-details" />
                                </div>
                                <div className="project-gallery-image">
                                    <img src={projectimg3} alt="project-details" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-65">
                        <div className="col-12 col-xl-4">
                            <div className="project-info-widget-wrapper">
                                <div className="project-info-widget">
                                    <div className="info-item">
                                        <h3>Project Type</h3>
                                        <p>Web Development</p>
                                    </div>
                                    <div className="info-item">
                                        <h3>Duration</h3>
                                        <p>1 Year</p>
                                    </div>
                                    <div className="info-item">
                                        <h3>Task</h3>
                                        <p>Research, Development</p>
                                    </div>
                                </div>
                                <div className="social-share">
                                    <span className="social-share-title">Share</span>
                                    {/* <a className="facebook" href="javascript:void(0)"><i className="fa-brands fa-facebook-f"></i></a> */}
                                    <a className="twitter" href="https://twitter.com/tktechnico"><i className="fa-brands fa-x-twitter"></i></a>
                                    <a className="linkedin" href="https://www.linkedin.com/company/tktechnico-solutions/"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-8 project-details-padding-left">
                            <div className="tiny-content">
                                <p>This pertains to the process of delivering a product in the realm of web development. It encompasses the systematic and strategic deployment of web-based solutions, ensuring efficient implementation and successful delivery. This involves various stages, from initial planning and coding to comprehensive testing and eventual release. The goal is to achieve a seamless and high-quality delivery, meeting the needs and expectations of the end-users. This process may involve iterative development cycles, continuous integration, and adherence to best practices to ensure a reliable and effective product delivery in the dynamic landscape of web development.</p>
                            </div>
                            <div className="mt-30 mt-lg-0">
                                <h2 className="project-details-title">Problems</h2>
                                <p>In the realm of web development, several challenges may arise during the product delivery process. Timely delivery is often hindered by unexpected technical issues, scope changes, or evolving client requirements. Ensuring compatibility across various devices and browsers can be a persistent challenge, as the web landscape is diverse and rapidly evolving. Additionally, maintaining code quality and addressing bugs or glitches that may emerge during the testing phase can impact the smooth delivery of the final product. Striking a balance between meeting project deadlines and delivering a high-quality, error-free product requires meticulous planning, constant communication, and adaptive problem-solving throughout the web development life cycle.</p>
                            </div>
                            <div className="row mt-60 gx-sm-5">
                                <div className="col-12">
                                    <h2 className="project-details-title">Solutions</h2>
                                </div>
                                <div className="col-12 col-xl-6 col-md-6">
                                    <div className="list-item-wrapper">
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Conduct thorough requirements gathering sessions, involve stakeholders, and use tools like user stories and wireframes. Regularly communicate and document any changes to requirements.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Clearly define project scope from the outset. Implement a change control process to evaluate and approve any changes. Regularly revisit and update the project scope as needed.                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Establish open channels of communication among team members and stakeholders.                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Invest time in creating a detailed project plan with realistic timelines and resource allocation.                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Invest time in creating a detailed project plan with realistic timelines and resource allocation.                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 col-md-6">
                                    <div className="list-item-wrapper">
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Prioritize clean, maintainable code during development. Schedule time for code refactoring as part of regular development cycles.
                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Implement a comprehensive testing strategy, including unit testing, integration testing, and user acceptance testing.                                             </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Develop a detailed deployment plan, including rollback procedures.                                             </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Establish a support and maintenance plan before launch. Provide ongoing support for bug fixes, updates, and user assistance. Regularly monitor user feedback and address issues promptly.                                            </p>
                                        </div>
                                        <div className="list-item style-two">
                                            <div className="icon">
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                            <p className="text">
                                                Invest in user training and onboarding processes. Provide user documentation and support resources. Gather user feedback to identify and address usability issues.                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="process-step-area style-2">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="project-details-title">Process</h2>
                                        <p>Web development process into three key stages: Research, Development, and Deployment.</p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>01</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Research</h2>
                                                <p className="desc">
                                                    Identify the intended audience and gather insightful information that will serve as a foundation for the development process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>02</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Development</h2>
                                                <p className="desc">
                                                    Emphasize the importance of building a code architecture that is both modular and scalable during the development process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="process-step style-2">
                                            <div className="icon">
                                                <div className="count">
                                                    <span>03</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h2 className="title">Deploy</h2>
                                                <p className="desc">
                                                    Devise a comprehensive deployment strategy that incorporates rigorous testing within a staging environment to ensure robustness.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-40">
                                    <h2 className="project-details-title">Results</h2>
                                    <p>
                                        By focusing on user needs, implementing a robust and scalable codebase, and ensuring a smooth deployment process, the result is a web application that meets or exceeds user expectations, leading to high user satisfaction and positive feedback.                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="simillar-project-title mb-50">
                                    <h2>You Also Might Like</h2>
                                </div>
                            </div>
                            <div className="portfolio-slider-wrapper" dir="ltr" id="related_portfolio">
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio1} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Artificial Intelligence</span>
                                                    <h3 className="title"><a href="javascript:void(0)" onClick={onProjectDetailAI}>Transformative Potential: Navigating the World of Artificial Intelligence in a Few Words.</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onProjectDetailAI}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio3} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Software Testing</span>
                                                    <h3 className="title"><a href="javascript:void(0)" onClick={onProjectDetailSoftwareTesting}>Immersive Experiences: Strategic Software Testing: Ensuring Quality and Reliability in Product Delivery</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onProjectDetailSoftwareTesting}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-style-two">
                                    <div className="image">
                                        <img src={portfolio5} alt="portfolio one" />
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="content-inner">
                                                    <span className="sub-title">Application Development</span>
                                                    <h3 className="title"><a href="javascript:void(0)" onClick={onProjectDetailAppDev}>Innovation Unleashed: The Evolving Landscape of Software Development in the Modern Era.</a></h3>
                                                </div>
                                                <div className="btn-wrapper">
                                                    <a href="javascript:void(0)" onClick={onProjectDetailAppDev}><i className="fa-solid fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <div id="scrollTop" class="scrollup-wrapper">
                <div class="scrollup-btn">
                    <i class="fa fa-angle-up"></i>
                </div>
            </div>
        </div>
    )
}

export default WebDevelopmentDetail;